import { Link } from 'react-router-dom';

//utilities
import decodeHTMLEntities from '../../features/decodeHTMLEntities';

//constants
import STORY_CATEGORIES from '../../constans/story-categories';
import ABOUT_TEACHER_STORY_FIELDS from '../../constans/about-teacher-story-fields';
import TEACHER_STORY_FIELDS from '../../constans/teacher-story-fields';

//styles
import './style.css';

//icons
import checkIcon from '../../assets/icons/check-green.svg';
import closeIcon from '../../assets/icons/close-red.svg';
import indeterminateIcon from '../../assets/icons/indeterminate-grey.svg';

export default function StoriesTable({ stories, type, assessorsAmount }) {
    return (
        <div className='stories-table-wrapper'>
            <table className='table' data-story-type={type}>
                <thead>
                    <tr>
                        <th>№</th>
                        <th>Історія</th>
                        {
                            (type === 'teacher-stories')
                            && <th>Ідея</th>
                        }
                        <th>
                            {
                                (type === 'about-teacher-stories')
                                    ? 'Відправник'
                                    : 'Вчитель'
                            }
                        </th>
                        {
                            (type === 'about-teacher-stories')
                            && <th>Вчитель</th>
                        }
                        <th>Категорія історії</th>
                        <th>Середній бал історії</th>
                        {
                            (type === 'teacher-stories')
                            && <th>Середній бал ідеї</th>
                        }
                        <th>Оцінки історії</th>
                        {
                            (type === 'teacher-stories')
                            && <th>Оцінки ідеї</th>
                        }
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {stories?.map(story => {
                        return (
                            <tr key={story.id}>
                                <td>{story.id}</td>
                                <td>
                                    {(story.story_validation)
                                        ? (story.story_validation === 1)
                                            ? <img src={checkIcon} alt='icon' />
                                            : <img src={closeIcon} alt='icon' />
                                        : <img src={indeterminateIcon} alt='icon' />
                                    }
                                </td>
                                {
                                    (type === 'teacher-stories')
                                    && <td>
                                        {(story.dream_validation)
                                            ? (story.dream_validation === 1)
                                                ? <img src={checkIcon} alt='icon' />
                                                : <img src={closeIcon} alt='icon' />
                                            : <img src={indeterminateIcon} alt='icon' />
                                        }
                                    </td>
                                }
                                <td>
                                    {
                                        (type === 'about-teacher-stories')
                                            ? `${story.data[ABOUT_TEACHER_STORY_FIELDS.name]} ${story.data[ABOUT_TEACHER_STORY_FIELDS.surname]}`
                                            : `${story.data[TEACHER_STORY_FIELDS.name]} ${story.data[TEACHER_STORY_FIELDS.surname]}`
                                    }
                                </td>
                                {
                                    (type === 'about-teacher-stories')
                                    && <td>
                                        {decodeHTMLEntities(story.data[ABOUT_TEACHER_STORY_FIELDS.teacherName])
                                            .split(' ')
                                            .slice(0, 2)
                                            .join(' ')}
                                    </td>
                                }
                                <td>{STORY_CATEGORIES[story.category] || <img src={indeterminateIcon} alt='icon' />}</td>
                                <td>
                                    {story.story_rate || <img src={indeterminateIcon} alt='icon' />} / 100
                                </td>
                                {
                                    (type === 'teacher-stories')
                                    && <td>{story.dream_rate || <img src={indeterminateIcon} alt='icon' />} / 100 </td>
                                }
                                <td>
                                    {((story.story_validation)
                                        ? (story.story_validation === 1)
                                            ? (story.evaluation?.length || 0) + '/' + (assessorsAmount[story.category] || 0)
                                            : <img src={indeterminateIcon} alt='icon' />
                                        : <img src={indeterminateIcon} alt='icon' />)
                                    }
                                </td>
                                <td>
                                    {
                                        (type === 'teacher-stories') && ((story.dream_validation)
                                            ? (story.dream_validation === 1)
                                                ? (story.dream_evaluation?.length || 0) + '/' + (assessorsAmount[8] || 0)
                                                : <img src={indeterminateIcon} alt='icon' />
                                            : <img src={indeterminateIcon} alt='icon' />)
                                    }
                                </td>
                                <td>
                                    <Link
                                        className='btn btn-primary'
                                        to={((type === 'about-teacher-stories') ? '/about-teacher-story/' : '/teacher-story/') + story.id}>
                                        Читати
                                    </Link>
                                </td>
                            </tr>
                        )

                    })}
                </tbody>
            </table>
        </div>
    )
}
