const STORY_CATEGORIES = [
    'Без категорії',
    'Вибір серцем',
    'Вчитель-волонтер',
    'Вчитель-незламність',
    'Вчитель-новатор',
    'Інші цікаві історії',
    'Молодий лідер освіти',
    'Вчитель дошкільної освіти'
]

export default STORY_CATEGORIES;