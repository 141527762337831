import { useState, useEffect } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';


//components
import Loader from '../../components/Loader/Loader';

//states
import { selectCurrentUser, selectAdminRole } from '../../features/authSlice';

//styles
import './style.css';

//images
import cover from '../../assets/img/cover.png';

export default function HomePage() {
    const location = useLocation();
    const navigate = useNavigate();

    const methodologyLink = 'https://drive.google.com/file/d/1YooJGH6-U5j1IO4zDnQvAzUw5ARTmfJH/';
    const telegramSupportLink = 'https://t.me/kysylenko/';
    const supportEmail = 'cto@osvitoria.org';

    const currentUser = useSelector(selectCurrentUser);
    const hasAdminRole = useSelector(selectAdminRole);

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        hasAdminRole && navigate('/categories');
        setIsLoading(false);
    }, [hasAdminRole, currentUser, navigate]);

    return (
        <>
            {(currentUser || location.state?.access)
                ? <main className='home-page'>
                    <div className='row'>
                        <div className='col-12'>
                            <h1>
                                Вітаємо!
                            </h1>
                        </div>
                        <div className='col-6 home-page-text'>
                            <p>
                                Дякуємо, що долучились до нашого проєкту <span className='yellow'>#ВчителіВажливі</span>, для нас дуже цінна ваша думка.
                            </p>
                            <p>
                               Нагадуємо, що ви можете <span className='blue'>оцінити кожну історію лише 1 раз</span>.
                            </p>
                            <p>
                                У разі виникнення будь-яких питань, зверніться до технічної підтримки за допомогою <a href={telegramSupportLink} target='_blank' rel='noreferrer'>Telegram</a> aбо <a href={'mailto:' + supportEmail}>електронної пошти</a>.
                            </p>
                            <p>
                                <a href={methodologyLink} target='_blank' rel='noreferrer'>За покликанням</a> ви можете ознайомитись з методологією оцінювання історій у 2023 році.
                            </p>
                        </div>
                        <div className='col-6'>
                            <div className='home-page-cover'>
                               <img src={cover} alt='cover' />
                            </div>
                            <div className='home-page-btn'>
                                <Link className='btn' to={'/category'}>
                                    До історій
                                </Link>
                            </div>
                        </div>
                    </div>
                </main>
                : <main className='home-page-login'>
                    <div className='home-page-login-card'>
                        <h1>
                            Будь ласка, увійдіть у систему
                        </h1>
                        <div>
                            <Link className='btn' to={'/login'}>
                                Вхід
                            </Link>
                        </div>
                    </div>
                </main>
            }
            {isLoading && <Loader />}
        </>
    )
}
