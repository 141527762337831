import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

//constants
import SERVER_URL from '../../constans/server-url';
import DREAM_CATEGORY_ID from '../../constans/dream-category-id';

//styles
import './style.css';

export default function CategoryCard({ type, category, amount, id, assessors, forms }) {
    const navigate = useNavigate();

    const [storiesAmount, setStoriesAmount] = useState(0);
    const [evalStoriesAmount, setEvalStoriesAmount] = useState(0);

    useEffect(() => {
        if (id && id < DREAM_CATEGORY_ID) {
            axios.post(SERVER_URL + '/api/attribute/amount-by-category', { category: id })
                .then((response) => { setStoriesAmount(response.data[0].amount) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        } else if (id && id === DREAM_CATEGORY_ID) {
            axios.get(SERVER_URL + '/api/attribute/read-dreams-amount')
                .then((response) => { setStoriesAmount(response.data[0].amount) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, navigate]);


    useEffect(() => {
        if (id && id < DREAM_CATEGORY_ID && forms) {
            axios.post(SERVER_URL + '/api/assessments/read-assessments-amount-by-forms', { forms: forms })
                .then((response) => { setEvalStoriesAmount(response.data[0].amount) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        } else if (id && id === DREAM_CATEGORY_ID) {
            axios.get(SERVER_URL + '/api/assessments/read-dreams-assessments-amount')
                .then((response) => { setEvalStoriesAmount(response.data[0].amount) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, forms, navigate]);

    if (type === 'inactive') {
        return (
            <div className='category category-inactive'>
                <div className='category-content category-inactive-content'>
                    <h2>
                        {category}
                    </h2>
                    <div>
                        <p>Заявки</p>
                        <p>{isNaN(amount) ? 0 : amount}</p>
                    </div>
                    <div className='category-content-links'>
                        <Link
                            className='btn'
                            to={(category === 'Відхилені') ? '/unpassed-stories' : '/unverified-stories'}>
                            До історій
                        </Link>
                    </div>
                </div>
            </div>
        )
    } else if (type === 'dream') {
        return (
            <div className='category category-dream'>
                <div className='category-content'>
                    <h2>
                        Освітня Ідея
                    </h2>
                    <div>
                        <p>Ідея</p>
                        <p>{storiesAmount}</p>
                    </div>
                    <div>
                        <p>Підключені експерти</p>
                        <p>{assessors || 0}</p>
                    </div>
                    <div>
                        <p>Оцінок</p>
                        <p>
                            {
                                (storiesAmount * (assessors || 0) === 0)
                                    ? '-'
                                    : `${evalStoriesAmount} / ${storiesAmount * (assessors || 0)}`
                            }
                        </p>
                    </div>
                    <div className='category-content-links'>
                        <Link className='btn' to={'/category/' + DREAM_CATEGORY_ID}>До ідей</Link>
                        <Link className='btn' to={'/categories/control/' + DREAM_CATEGORY_ID}>Керувати експертами</Link>
                    </div>
                </div>
            </div>
        )
    } else {
        return (
            <div className='category'>
                <div className='category-content'>
                    <h2>
                        {category}
                    </h2>
                    <div>
                        <p>Історії</p>
                        <p>{storiesAmount}</p>
                    </div>
                    <div>
                        <p>Підключені експерти</p>
                        <p>{assessors || 0}</p>
                    </div>
                    <div>
                        <p>Оцінок</p>
                        <p>
                            {
                                (storiesAmount * (assessors || 0) === 0)
                                    ? '-'
                                    : `${evalStoriesAmount} / ${storiesAmount * (assessors || 0)}`
                            }
                        </p>
                    </div>
                    <div className='category-content-links'>
                        <Link className='btn' to={'/category/' + id}>До історій</Link>
                        <Link className='btn' to={'/categories/control/' + id}>Керувати експертами</Link>
                    </div>
                </div>
            </div>
        )
    }
}
