//styles
import './style.css';

export default function RatingScale({ type }) {
  return (
    <div className='rating-scale'>
      <h3 className='rating-scale-title'>
        Шкала балів відповідно до критеріїв оцінювання:
      </h3>
      {(type === 'story')
        ? <div className='rating-scale-content'>
          <p>
            <b>1-3 бали</b> – історія описує подію, але не надає достатньо контексту, не пояснює причини або наслідки цієї події.
          </p>
          <p>
            <b>4-6 балів</b> – загалом історія зрозуміла, описує загальну подію, але пропущені важливі деталі та уточнення, відсутнє логічне завершення історії.
          </p>
          <p>
            <b>7-10 балів</b> – історія повністю описує подію, надаючи вичерпну і детальну інформацію про подію, контекст, причини і наслідки. Інформація добре структурована та логічна, висвітлює ключові аспекти події та відношення між ними. Історія вчителя викликає значне емоційне піднесення, вона надихає та мотивує; відчутна зацікавленість учителя в результатах, розуміння свого внеску й виразне бажання продовжувати свою діяльність (усвідомлення місії та наявність візії).
          </p>
        </div>
        : <div className='rating-scale-content'>
          <p>
            <b>1-3 бали</b> – проєкт слабо розроблений і не відображає вагомого впливу на освіту. Відсутність чіткої визначеності щодо мети, стратегії та ресурсів для реалізації.
          </p>
          <p>
            <b>4-6 балів</b> – проєкт має певний потенціал, але потребує більшої конкретизації та уточнень. Відсутність повного опису стратегії та ресурсів, щоб досягти мети.
          </p>
          <p>
            <b>7-8 балів</b> – проєкт добре розроблений та має конкретну мету. Існує загальна стратегія, але можуть бути відсутні деякі деталі щодо реалізації. Існують вказівки про можливі ресурси.
          </p>
          <p>
            <b>9-10 балів</b> – проєкт чітко сформульований та має високий потенціал впливу на освіту. Стратегія проєкту докладно розроблена, зазначені необхідні ресурси та способи їх залучення. Проєкт є реалістичним, цілеспрямованим та можливим до втілення.
          </p>
        </div>
      }
    </div>
  )
}
