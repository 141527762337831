const TEACHER_STORY_FIELDS = {
    name: 'first-name',
    surname: 'last-name',
    tel: 'tel',
    email: 'email',
    city: 'city',
    story: 'story',
    file1: 'file-1cfdb7_file',
    file2: 'file-2cfdb7_file',
    file3: 'file-3cfdb7_file',
    file4: 'file-4cfdb7_file',
    url: 'url',
    dream: 'dream',
    communicatePermission: 'permission-to-communicate',
}

export default TEACHER_STORY_FIELDS;