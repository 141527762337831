import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import StoryNav from '../../components/StoryNav/StoryNav';
import CommentInput from '../../components/CommentInput/CommentInput';

//states
import { selectCurrentUser } from '../../features/authSlice';

//constants
import SERVER_URL from '../../constans/server-url';

//styles
import './style.css';

export default function CommentsPage() {
    const { id } = useParams();

    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const [comments, setComments] = useState([]);
    const [comment, setComment] = useState('');
    const [commentLength, setСommentLength] = useState(0);
    const [commentHeight, setСommentHeight] = useState('80px');

    useEffect(() => {
        id && axios.post(SERVER_URL + '/api/comment/read-all', {
            'form_id': id
        })
            .then((response) => {
                let { data } = response;

                let result = data.map(item => {
                    item.date = (new Date(item.date)).toLocaleString('UK');
                    return item;
                });
                setComments(result);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, navigate]);

    const commentHandler = (e) => {
        let feedbackText = e.target.value;
        if (feedbackText.length <= 1000) {
            setComment(e.target.value);
            setСommentLength(feedbackText.length);
            if (e.target.scrollHeight > 80) {
                setСommentHeight(e.target.scrollHeight + 'px');
            } else {
                setСommentHeight('80px');
            }
        } else {
            return;
        }
    }

    const sendComment = (e) => {
        e.preventDefault();
        let date = new Date();
        if (id) {
            axios.post(SERVER_URL + '/api/comment/create', {
                'form_id': id,
                'username': currentUser.username,
                'date': date,
                'text': comment
            })
                .then(() => {
                    window.location.reload();
                })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }

    return (
        <main>
            <Breadcrumbs />
            <StoryNav id={id} />
            <h1 className='comments-title'>
                Історія коментарів:
            </h1>
            <div className='comments'>
                {(comments.length === 0)
                    ? <p>Коментарів немає.</p>
                    : comments.map((comment, index) => {
                        return (<div key={index} className='comment'>
                            <h3>{comment.username} <span>{comment.date}</span></h3>
                            <p>{comment.text}</p>
                        </div>)
                    })}
            </div>
            <div>
                <CommentInput
                    text={comment}
                    handler={commentHandler}
                    length={commentLength}
                    height={commentHeight}
                    action={sendComment}
                />
            </div>
        </main>
    )
}
