import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import Loader from '../../components/Loader/Loader';

//states
import { selectAdminRole } from '../../features/authSlice';

//services
import authHeader from '../../services/auth-header';
import AuthService from '../../services/auth.service';

//constants
import SERVER_URL from '../../constans/server-url';
import ALL_CATEGORIES from '../../constans/all-categories';

//styles
import './style.css';

export default function UserManagement() {
    const navigate = useNavigate();

    const hasAdminRole = useSelector(selectAdminRole);

    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [filterCategory, setFilterCategory] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        hasAdminRole && axios.get(SERVER_URL + '/api/users-with-roles', { headers: authHeader() })
            .then(
                (response) => {
                    setIsLoading(true);
                    setUsers(response.data);
                    setIsLoading(false);
                }
            )
            .catch(error => {
                if (error.response.status === 401) {
                    AuthService.logout();
                    navigate('/login');
                }
                navigate('/error', { state: { error: error.response.data.message } })
            });
    }, [hasAdminRole, navigate]);

    useEffect(() => {
        if (filterCategory === undefined) {
            setFilteredUsers(users);
        } else {
            let allUsers = users.filter(user => {
                return user.category === filterCategory;
            });
            setFilteredUsers(allUsers);
        }
    }, [users, filterCategory]);

    const filterHandler = (e) => {
        const { category } = e.target.dataset;
        if (category === 'undefined') {
            setFilterCategory(undefined);
        } else if (category === 'null') {
            setFilterCategory(null);
        } else {
            setFilterCategory(+category);
        }
    }

    return (
        <>{hasAdminRole
            ? <div className='container user-management-container'>
                <div className='row my-5'>
                    <div className='col-8'>
                        <h3>
                            Користувачі
                        </h3>
                    </div>
                    <div className='col-4 d-flex justify-content-end'>
                        <Link className='btn user-management-btn' to={'/register'}>
                            Додати
                        </Link>
                    </div>
                </div>
                <div className='row'>
                    <div className='col-2'>
                        <button
                            className={(filterCategory === undefined) ? 'btn active' : 'btn'}
                            onClick={e => filterHandler(e)}
                            data-category='undefined'>
                            Всі категорії
                        </button>
                        {ALL_CATEGORIES.map((category, index) => {
                            return (
                                <button
                                    className={(index === +filterCategory) ? 'btn active' : 'btn'}
                                    key={category}
                                    onClick={e => filterHandler(e)}
                                    data-category={index === 0 ? 'null' : index}>
                                    {category}
                                </button>
                            )
                        })}
                    </div>
                    <div className='col-10'>
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Ім'я та прізвище</th>
                                    <th>E-mail</th>
                                    <th>Категорія</th>
                                    <th>Роль</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    filteredUsers.length > 0 && filteredUsers.map(user => {
                                        return (
                                            <tr key={user.username}>
                                                <td> {user.username} </td>
                                                <td> {user.email} </td>
                                                <td> {ALL_CATEGORIES[user.category] || 'Не призначено'} </td>
                                                <td> {(user.roles.length === 3)
                                                    ? 'Адміністратор'
                                                    : (user.roles.length === 2)
                                                        ? 'Експерт'
                                                        : 'Амбасадор'} </td>
                                            </tr>
                                        )
                                    })
                                }
                                {
                                    filteredUsers.length === 0 && <tr><td colSpan={4}>Користувачів не знайдено</td></tr>
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            : <div className='container'>
                У вас немає прав доступу для перегляду користувачів.
            </div>}
            {isLoading && <Loader />}
        </>
    )
}
