import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import StoryNav from '../../components/StoryNav/StoryNav';
import Criterions from '../../components/Criterions/Criterions';
import Loader from '../../components/Loader/Loader';

//states
import { selectAdminRole } from '../../features/authSlice';

//constants
import SERVER_URL from '../../constans/server-url';
import STORY_CRITERIA from '../../constans/story-criteria';
import DREAM_CRITERIA from '../../constans/dream-criteria';

//styles
import './style.css';

export default function EvaluationsPage() {
    const { id } = useParams();
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const hasAdminRole = useSelector(selectAdminRole);

    const [storyAssessments, setStoryAssessments] = useState([]);
    const [dreamAssessments, setDreamAssessments] = useState([]);

    useEffect(() => {
        if (id && hasAdminRole) {
            setIsLoading(true);
            axios.post(SERVER_URL + '/api/assessments/read-assessments-evaluation-and-feedback', {
                form_id: id
            })
                .then((response) => {
                    let { data } = response;
                    if (data) {
                        data.map(item => item.evaluation = JSON.parse(item.evaluation));

                        let storyAssessments = data.filter(item => item.type === 0)
                        setStoryAssessments(storyAssessments);

                        let dreamAssessments = data.filter(item => item.type === 1)
                        setDreamAssessments(dreamAssessments);

                        setIsLoading(false);
                    }
                })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, hasAdminRole, navigate]);

    return (
        <div className='evaluations'>
            <Breadcrumbs />
            <StoryNav id={id} />

            <div className='row'>
                <div className='evaluations-header'>
                    <h2>Оцінка історії</h2>
                    <div>
                        <span>Середній бал:</span>
                        {storyAssessments.length > 0
                            ? ((storyAssessments.reduce((prev, curr) => {
                                return prev + Object.values(curr.evaluation).reduce((prev, curr) => prev + +curr, 0)
                            }, 0)) / 60 * 100 / storyAssessments.length).toFixed(2) + '% / 100%'
                            : '- / 100%'}
                    </div>
                </div>
                {storyAssessments.map((assessment, index) => {
                    return <Criterions key={index} assessment={assessment} criteria={STORY_CRITERIA} max={60} />
                })}
            </div>

            <div className='row'>
                <div className='evaluations-header'>
                    <h2>Оцінка мрії</h2>
                    <div>
                        <span>Середній бал:</span>
                        {dreamAssessments.length > 0
                            ? ((dreamAssessments.reduce((prev, curr) => {
                                return prev + Object.values(curr.evaluation).reduce((prev, curr) => prev + +curr, 0)
                            }, 0)) / 50 * 100 / dreamAssessments.length).toFixed(2) + '% / 100%'
                            : '- / 100%'}
                    </div>
                </div>

                {dreamAssessments.map((assessment, index) => {
                    return <Criterions key={index} assessment={assessment} criteria={DREAM_CRITERIA} max={50} />
                })}
            </div>

            {isLoading && <Loader />}
        </div>
    )
}
