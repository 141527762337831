import { Link } from 'react-router-dom';

//icons
import arrow from '../../assets/icons/circle-arrow-left.png';

export default function Breadcrumbs({ url = '/unverified-stories', text = 'До неперевірених історій' }) {
    return (
        <div className='story-breadcrumbs'>
            <Link to={url}>
                <img src={arrow} alt='arrow' />
                {text}
            </Link>
        </div>
    )
}
