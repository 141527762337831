//styles
import './style.css';

//icons
import closeIcon from '../../assets/icons/close-red.svg';

export default function CommentsModal({ comments, closeHandler }) {
    return (
        <div className='comments-modal-component'>
            <div className='comments-modal-component-content'>
                <div className='comments-modal-component-control'>
                    <button onClick={closeHandler}>
                        <img src={closeIcon} alt='icon' />
                    </button>
                </div>
                {comments.length > 0
                    ? <>
                        {
                            comments.map((comment, index) => {
                                return <div key={index} className='comments-modal-component-comment'>
                                    <h6>{comment.username} <span>{comment.date}</span></h6>
                                    <p>{comment.text}</p>
                                </div>
                            })
                        }
                    </>
                    : 'Коментарів немає.'
                }
            </div>
        </div>
    )
}
