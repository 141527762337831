import React, { useState, useEffect } from 'react';
import { Routes, Route } from 'react-router-dom';

//services
import AuthService from './services/auth.service';

//pages
import Login from "./pages/Login/Login";
import Register from "./pages/Register/Register";
import Profile from "./pages/Profile/Profile";
import AllStoriesPage from './pages/AllStoriesPage/AllStoriesPage';
import UserManagement from "./pages/UserManagement/UserManagement";
import Error from "./pages/Error/Error";
import CategoriesPage from './pages/CategoriesPage/CategoriesPage';
import StoryControlPage from './pages/StoryControlPage/StoryControlPage';
import AboutTeacherStory from "./pages/AboutTeacherStory/AboutTeacherStory";
import CommentsPage from './pages/CommentsPage/CommentsPage';
import EvaluationsPage from './pages/EvaluationsPage/EvaluationsPage';
import AssessorsControlPage from './pages/AssessorsControlPage/AssessorsControlPage';
import HomePage from './pages/HomePage/HomePage';
import CategoryPage from './pages/CategoryPage/CategoryPage';
import TeacherStoryPage from './pages/TeacherStoryPage/TeacherStoryPage';
import UnverifiedStoriesPage from './pages/UnverifiedStoriesPage/UnverifiedStoriesPage';
import UnpassedStoriesPage from './pages/UnpassedStoriesPage/UnpassedStoriesPage';

//components
import Nav from "./components/Nav/Nav";
import Footer from './components/Footer/Footer';

import EventBus from "./common/EventBus";

import { useSelector, useDispatch } from "react-redux";
import { setAdminRole, setCurrentUserR, setModeratorRole, userIsLogin, userIsLogout } from "./features/authSlice";


import { selectCurrentUser, selectModeratorRole, selectAdminRole } from "./features/authSlice";

//styles
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';

export default function App() {
  const isLoged = useSelector((state) => state.auth.value);
  const currentUserRedux = useSelector(selectCurrentUser);

  const hasAdminRole = useSelector(selectModeratorRole);

  const dispatch = useDispatch();

  const [showModeratorBoard, setShowModeratorBoard] = useState(false);
  const [showAdminBoard, setShowAdminBoard] = useState(false);
  const [currentUser, setCurrentUser] = useState(undefined);

  useEffect(() => {
    const user = AuthService.getCurrentUser();

    if (user) {
      dispatch(userIsLogin());
      setCurrentUser(user);
      dispatch(setCurrentUserR(user));
      setShowModeratorBoard(user.roles.includes("ROLE_MODERATOR"));
      setShowAdminBoard(user.roles.includes("ROLE_ADMIN"));

      if (user.roles.includes("ROLE_MODERATOR")) dispatch(setModeratorRole());
      if (user.roles.includes("ROLE_ADMIN")) dispatch(setAdminRole());
    }

    EventBus.on("logout", () => {
      logOut();
    });

    return () => {
      EventBus.remove("logout");
    };
  }, []);

  const logOut = () => {
    AuthService.logout();
    setShowModeratorBoard(false);
    setShowAdminBoard(false);
    setCurrentUser(undefined);
  };

  return (
    <>
      <Nav
        showAdminBoard={showAdminBoard}
        currentUser={currentUser}
        logOut={logOut}
      />

      <div className="container">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/login" element={<Login />} />
          <Route path="/error" element={<Error />} />
          <Route path="*" element={<p></p>} />

          {currentUser
            && <>
              <Route path="/category" element={<CategoryPage />} />
              <Route path="/about-teacher-story/:id" element={<AboutTeacherStory />} />
              <Route path="/teacher-story/:id" element={<TeacherStoryPage />} />
              <Route path="/profile" element={<Profile />} />
            </>
          }

          {showAdminBoard
            && <>
              <Route path="/register" element={<Register />} />
              <Route path="/categories" element={<CategoriesPage />} />
              <Route path="/category/:id" element={<CategoryPage />} />
              <Route path="/categories/control/:id" element={<AssessorsControlPage />} />
              <Route path="/stories" element={<AllStoriesPage />} />
              <Route path="/unverified-stories" element={<UnverifiedStoriesPage />} />
              <Route path="/unpassed-stories" element={<UnpassedStoriesPage />} />
              <Route path="/story-control/:id" element={<StoryControlPage />} />
              <Route path="/comments/:id" element={<CommentsPage />} />
              <Route path="/evaluations/:id" element={<EvaluationsPage />} />
              <Route path="/users" element={<UserManagement />} />
            </>
          }
        </Routes>
      </div>
      <Footer />
    </>
  );
};