const vs = [
    2383, 2524, 2556, 2647, 2428, 2886, 2919, 2944, 2951, 2968, 2971, 2572, 2634, 2680, 2766, 3030, 3173, 3161, 3137,
    3070, 3062,
];
const vz1 = [
    2595, 2638, 2650, 2696, 2884, 2902, 2939, 2942, 2958, 2613, 2703, 2724, 2778, 2855, 2873, 2874, 2913, 2957, 3050,
    3158, 3181, 3189, 2994, 3017, 3183, 2804, 3032, 3043, 3209, 2402, 2413, 2378, 2381, 2403, 2433, 2437, 2466, 2473,
    2480, 2499, 2525, 2605, 2529, 2555, 2558, 2563, 2578, 2587, 2625, 2655, 2684, 2686, 2687, 2424, 2501, 2409, 2445,
    2591, 2519, 3208,
];
const vz2 = [
    2545, 2579, 2373, 2689, 2706, 2707, 2714, 2756, 2765, 2677, 2825, 2827, 2840, 2865, 2867, 2870, 2975, 2511, 2977,
    2440, 2508, 2517, 2544, 2550, 2583, 2601, 2813, 2821, 2836, 2842, 2425, 2552, 2573, 2912, 2960, 2979, 3048, 3132,
    3170, 3174, 3193, 2996, 3001, 3172, 3166, 3155, 3143, 3142, 3019, 3127, 3046, 3071, 3026, 3034, 3035, 3036, 3037,
    3042, 3058, 3130, 3207,
];
const vn1 = [
    2604, 2484, 2523, 2557, 2571, 2631, 2651, 2387, 2449, 2360, 2368, 2396, 2411, 2527, 2536, 2597, 2586, 2568, 2606,
    2530, 2510, 2483, 2492, 2407, 2438, 2435, 2526, 2414, 2633, 2642, 2659, 2663, 2679, 2701, 2702, 2712, 2719, 2732,
    2736, 2748, 2755, 2399, 2833, 2844,
];
const vn2 = [
    2848, 2854, 2861, 2887, 2899, 2900, 2901, 2906, 2909, 2911, 2917, 2937, 2949, 2967, 2969, 2982, 2984, 2987, 2513,
    2621, 2643, 2648, 2654, 2710, 2713, 2723, 2734, 2822, 2896, 2980, 2981, 3052, 3136, 3160, 3009, 3197, 3139, 3126,
    3103, 3102, 3089, 3079, 3024, 3041, 3045, 3060,
];
const mlo = [2444, 2477, 2488, 2491, 2602, 2852, 2882, 2903, 2866, 3023, 3118, 3194, 3195, 3140, 3049, 3021, 3198];
const vdo1 = [
    2476, 2489, 2532, 2543, 2945, 2722, 2726, 2727, 2739, 2831, 2904, 2908, 2916, 2922, 2924, 2934, 2935, 2940, 2947,
    2972, 2973, 2978, 2521, 2791, 2522, 2729, 2738, 2741, 2742, 2743, 2744, 2746, 2747, 2751, 2753, 2760, 2781, 2782,
    2783, 2784, 2785, 2786, 2787, 2788, 2789, 2790,
];
const vdo2 = [
    2792, 2793, 2794, 2796, 2988, 2907, 2930, 3182, 2952, 2955, 2970, 2985, 3011, 3012, 3053, 3066, 3076, 3091, 3093,
    3114, 3116, 3138, 3151, 3191, 2989, 2990, 2993, 2995, 2997, 3002, 3003, 3004, 3006, 3010, 3013, 3200, 3190, 3188,
    3186, 3169, 3167, 3162, 3157, 3156, 3154, 3153,
];
const vdo3 = [
    3147, 3146, 3145, 3141, 3135, 3134, 3020, 3133, 3129, 3125, 3124, 3121, 3120, 3119, 3111, 3110, 3040, 3098, 3092,
    3090, 3086, 3085, 3084, 3051, 3054, 3078, 3077, 3075, 3074, 3073, 3072, 3022, 3027, 3028, 3033, 3038, 3055, 3059,
    3064, 3067, 3065, 3068, 3063, 3057, 3123, 3150,
];

const STORY_SETTINGS = {
    'n.moseichuk@1plus1.tv': vs,
    'a.sergienko@1plus1.tv': vs,
    'arthurproydakov@gmail.com': vz1,
    'tabolina.liudmyla@gmail.com': vz1,
    'cherkasasha@ukr.net': vz1,
    'Oleynik-1993@i.ua': vz2,
    'Lidiyapovoroznyk@gmail.com': vz2,
    'ppshenichka@gmail.com': vz2,
    'zyma.nataliia.km@gmail.com': vn1,
    'zavidovski@gmail.com': vn1,
    'lus0561@gmail.com': vn1,
    'utamara42@gmail.com': vn2,
    'sokol28irina@gmail.com': vn2,
    'bratolesya@gmail.com': vn2,
    'ngvworkngv@gmail.com': mlo,
    'lexavzie@ukr.net': mlo,
    'tutaevamaya64@gmail.com': mlo,
    'vika.uzh@gmail.com': vdo1,
    'helena3167@ukr.net': vdo1,
    'leninianna3@gmail.com': vdo1,
    'ganna1971but@gmail.com': vdo2,
    '2108vgdac@gmail.com': vdo2,
    'oksanaratyshnak2015@gmail.com': vdo2,
    'vladyslav.kachur@gmail.com': vdo3,
    '31075yfnfksz@gmail.com': vdo3,
    'juk.tk@ua.fm': vdo3,
};

export default STORY_SETTINGS;
