//services
import transformText from '../../features/transformText';

export default function Feedback({text, showFullText, handler, limit}) {
    return (
        <div className='my-4 story-text'>
            <h4>Зворотній зв'язок</h4>
            <div>
                {transformText(text, showFullText, limit)}
                <button
                    className='story-text-btn'
                    onClick={handler}
                    hidden={text.length < limit}
                >
                    {(!showFullText) ? 'Показати більше' : 'Показати менше'}
                </button>
            </div>
        </div>
    )
}
