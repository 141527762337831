const STORY_CRITERIA = [
    {
        title: 'Вплив',
        description:
            'Оцінюється потенційний медійний вплив історії на цільову аудиторію (вчителі, батьки, учні, колеги), спільноту або сферу діяльності.',
    },
    {
        title: 'Внесок у відбудову освіти',
        description:
            'Оцінка активної участі вчителя у відбудові/відновленні/підтримка освітньої сфери та відновленні освітнього процесу, його допомоги в покращенні навчання та результатів учнів в складних воєнних умовах.',
    },
    {
        title: 'Інтеграція з громадою',
        description:
            'Оцінка активної участі вчителя у роботі з громадськістю, партнерстві з батьками, колегами та іншими зацікавленими сторонами для досягнення спільних цілей у сфері освіти в умовах війни.',
    },
    {
        title: 'Вплив на учнів',
        description:
            'Оцінюється, як вчитель змінює життя своїх учнів, надаючи їм позитивний вплив і надію на майбутнє в умовах війни Особисті історії успіху учнів можуть бути використані для підтвердження цього впливу.',
    },
    {
        title: 'Інноваційність/гнучкість',
        description:
            'Оцінюється, наскільки вчитель використовує новаторські та гнучкі підходи, методи та технології у своїй роботі, щоб забезпечити ефективне навчання та розвиток учнів в сучасних воєнних умовах.',
    },
    {
        title: 'Життєстійкість до викликів сьогодення',
        description:
            'Оцінюється комплекс навичок, який допоміг вчителю не лише пережити важкі часи, а навіть зміцнити себе чи здобути нові вміння і звички.',
    },
];

export default STORY_CRITERIA;
