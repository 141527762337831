import { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import StoryNav from '../../components/StoryNav/StoryNav';
import StoryInformation from '../../components/StoryInformation/StoryInformation';
import Files from '../../components/Files/Files';
import Criterions from '../../components/Criterions/Criterions';
import Loader from '../../components/Loader/Loader';
import Modal from './components/Modal/Modal';
import Evaluation from '../../components/Evaluation/Evaluation';
import StoryContacts from '../../components/StoryContacts/StoryContacts';
import Select from '../../components/Select/Select';

// import Select from './components/Select/Select';

//states
import { selectCurrentUser, selectAdminRole } from '../../features/authSlice';

//utilities
import decodeHTMLEntities from '../../features/decodeHTMLEntities';
import transformText from '../../features/transformText';

//constants
import SERVER_URL from '../../constans/server-url';
import FIELDS from '../../constans/teacher-story-fields';
import STORY_CRITERIA from '../../constans/story-criteria';
import DREAM_CRITERIA from '../../constans/dream-criteria';
import STORY_VALIDATION from '../../constans/story-validation';
import STORY_CATEGORIES from '../../constans/story-categories';
import DREAM_CATEGORY_ID from '../../constans/dream-category-id';

//styles
import './style.css';

export default function TeacherStoryPage() {
    const { id } = useParams();

    const navigate = useNavigate();

    const DISPLAYED_CHARACTERS_AMOUNT = 400;

    const currentUser = useSelector(selectCurrentUser);
    const hasAdminRole = useSelector(selectAdminRole);

    const [showFullStory, setShowFullStory] = useState(false);
    const [showFullDream, setShowFullDream] = useState(false);

    const [showFullStoryForm, setShowFullStoryForm] = useState(false);
    const [showFullDreamForm, setShowFullDreamForm] = useState(false);

    const [isLoading, setIsLoading] = useState(true);
    const [showStoryModal, setShowStoryModal] = useState(false);
    const [showDreamModal, setShowDreamModal] = useState(false);

    const [story, setStory] = useState('');

    const [storyCriteria, setStoryCriteria] = useState({ 0: '1', 1: '1', 2: '1', 3: '1', 4: '1', 5: '1' });
    const [dreamCriteria, setDreamCriteria] = useState({ 0: '1', 1: '1', 2: '1', 3: '1', 4: '1' });

    const [storyFeedback, setStoryFeedback] = useState('');
    const [storyFeedbackLength, setStoryFeedbackLength] = useState(0);
    const [storyFeedbackHeight, setStoryFeedbackHeight] = useState('80px');

    const [dreamFeedback, setDreamFeedback] = useState('');
    const [dreamFeedbackLength, setDreamFeedbackLength] = useState(0);
    const [dreamFeedbackHeight, setDreamFeedbackHeight] = useState('80px');

    const [storyAssessment, setStoryAssessment] = useState([]);
    const [dreamAssessment, setDreamAssessment] = useState([]);

    const [category, setCategory] = useState('0');
    const [storyValidation, setStoryValidation] = useState('0');
    const [dreamValidation, setDreamValidation] = useState('0');

    const [userCategoryId, setUserCategoryId] = useState(undefined);

    useEffect(() => {
        currentUser && axios.post(SERVER_URL + '/api/user/read-user-category', { userId: currentUser.id })
            .then((response) => { setUserCategoryId(response.data.category) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [currentUser, navigate]);

    //read story
    useEffect(() => {
        setIsLoading(true);
        axios.post(SERVER_URL + '/api/teacher-story', {
            id
        })
            .then((response) => {
                let { data } = response;
                let storyToJSON = JSON.parse(data.data);
                storyToJSON.story = decodeHTMLEntities(storyToJSON.story);
                storyToJSON.dream = decodeHTMLEntities(storyToJSON.dream);
                storyToJSON[FIELDS.name] = decodeHTMLEntities(storyToJSON[FIELDS.name]);
                storyToJSON[FIELDS.surname] = decodeHTMLEntities(storyToJSON[FIELDS.surname]);
                storyToJSON[FIELDS.tel] = decodeHTMLEntities(storyToJSON[FIELDS.tel]);
                setStory(storyToJSON);
                setIsLoading(false);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, navigate]);


    //read user assesment
    useEffect(() => {
        if (id && currentUser && !hasAdminRole) {
            setIsLoading(true);

            let requestParameters = {
                user_id: currentUser.id,
                form_id: id,
            }

            axios.all([
                axios.post(SERVER_URL + '/api/assessment/story/read', requestParameters),
                axios.post(SERVER_URL + '/api/assessment/dream/read', requestParameters),
            ])
                .then(axios.spread((data1, data2) => {
                    let storyAssessmentData = data1.data;
                    if (storyAssessmentData) {
                        storyAssessmentData.evaluation = JSON.parse(storyAssessmentData.evaluation);
                        setStoryAssessment(storyAssessmentData);
                    }

                    let dreamAssessmentData = data2.data;
                    if (dreamAssessmentData) {
                        dreamAssessmentData.evaluation = JSON.parse(dreamAssessmentData.evaluation);
                        setDreamAssessment(dreamAssessmentData);
                    }

                    setIsLoading(false);
                }))
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [currentUser, id, navigate, hasAdminRole]);


    //
    useEffect(() => {
        if (id) {
            axios.post(SERVER_URL + '/api/attribute/read', {
                form_id: id
            })
                .then(
                    (response) => {
                        let storyValidation = response.data.story_validation;
                        if (storyValidation === 1 || storyValidation === 2) {
                            setStoryValidation(storyValidation.toString());
                        }

                        let dreamValidation = response.data.dream_validation;
                        if (dreamValidation === 1 || dreamValidation === 2) {
                            setDreamValidation(dreamValidation.toString());
                        }

                        let category = response.data.category || '0';
                        setCategory(category.toString());

                        setIsLoading(false);
                    }
                )
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, navigate]);

    const sendFeedback = (e, type, evaluation, feedback) => {
        e.preventDefault();
        setIsLoading(true);
        axios.post(SERVER_URL + '/api/assessment/create', {
            user_id: currentUser.id,
            username: currentUser.username,
            email: currentUser.email,
            form_id: id,
            type: type,
            evaluation: JSON.stringify(evaluation),
            feedback: feedback
        })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                } else {
                    navigate('/error', 'Заявка не відправлена через помилку сервера.');
                }
            })
            .catch(error => navigate('/error', error.response.data.message));
    }

    const sendStoryFeedback = (e) => {
        sendFeedback(e, 0, storyCriteria, storyFeedback);
    }

    const sendDreamFeedback = (e) => {
        sendFeedback(e, 1, dreamCriteria, dreamFeedback);
    }

    const showFullStoryHandler = () => {
        setShowFullStory((prevState) => !prevState);
    }

    const showFullDreamHandler = () => {
        setShowFullDream((prevState) => !prevState);
    }

    const storyFeedbackHandler = (e) => {
        let feedbackText = e.target.value;
        if (feedbackText.length <= 1000) {
            setStoryFeedback(e.target.value);
            setStoryFeedbackLength(feedbackText.length);
            if (e.target.scrollHeight > 80) {
                setStoryFeedbackHeight(e.target.scrollHeight + 'px');
            } else {
                setStoryFeedbackHeight('80px');
            }

        } else {
            return;
        }
    }

    const dreamFeedbackHandler = (e) => {
        let feedbackText = e.target.value;
        if (feedbackText.length <= 1000) {
            setDreamFeedback(e.target.value);
            setDreamFeedbackLength(feedbackText.length);
            if (e.target.scrollHeight > 80) {
                setDreamFeedbackHeight(e.target.scrollHeight + 'px');
            } else {
                setDreamFeedbackHeight('80px');
            }

        } else {
            return;
        }
    }

    const storyCriteriaHandler = (e, id) => {
        setStoryCriteria((prevState) => {
            prevState[id] = e.target.value;
            return prevState;
        });
    }

    const dreamCriteriaHandler = (e, id) => {
        setDreamCriteria((prevState) => {
            prevState[id] = e.target.value;
            return prevState;
        });
    }

    const transformStoryText = (text) => {
        return transformText(text, showFullStory, DISPLAYED_CHARACTERS_AMOUNT)
    }

    const transformDreamText = (text) => {
        return transformText(text, showFullDream, DISPLAYED_CHARACTERS_AMOUNT)
    }

    const storyModalHandler = (e) => {
        e.preventDefault();
        setShowStoryModal(prevState => !prevState);
    }

    const dreamModalHandler = (e) => {
        e.preventDefault();
        setShowDreamModal(prevState => !prevState);
    }

    const saveAttribute = () => {
        axios.post(SERVER_URL + '/api/attribute/create', {
            form_id: id,
            story_validation: storyValidation,
            dream_validation: dreamValidation,
            category: category
        })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
    }

    const categoryHandler = (e) => {
        setCategory(e.target.value);
    }

    const storyValidationHandler = (e) => {
        setStoryValidation(e.target.value);
        if (e.target.value === '0' || e.target.value === '2') {
            setCategory('0');
        }
    }

    const dreamValidationHandler = (e) => {
        setDreamValidation(e.target.value);
    }

    return (
        <>
            <main className='story-container'>
                <div className='row'>
                    {hasAdminRole && <Breadcrumbs />}
                    {hasAdminRole && <StoryNav id={id} type={1} />}
                    <div className='col-9'>
                        <StoryInformation
                            firstName={story[FIELDS.name]}
                            lastName={story[FIELDS.surname]}
                            city={story[FIELDS.city]}
                        />

                        {((userCategoryId && userCategoryId !== DREAM_CATEGORY_ID) || hasAdminRole)
                            && <>
                                <h4 className='story-text-title'>
                                    Історія:
                                </h4>
                                <div className='story-text'>
                                    {story[FIELDS.story] && transformStoryText(story[FIELDS.story])}
                                    {story[FIELDS.story]?.length < DISPLAYED_CHARACTERS_AMOUNT || <button
                                        className='story-text-btn'
                                        hidden={storyAssessment.storyFeedback?.length < DISPLAYED_CHARACTERS_AMOUNT}
                                        onClick={showFullStoryHandler}>
                                        {(!showFullStory) ? 'читати далі' : 'cховати'}
                                    </button>}
                                </div>
                                {(Object.keys(storyAssessment).length !== 0)
                                    ? <Criterions assessment={storyAssessment} criteria={STORY_CRITERIA} max={60} />
                                    : <Evaluation
                                        title='Ваша оцінка'
                                        criteria={storyCriteria}
                                        criteriaHandler={storyCriteriaHandler}
                                        criteriaArray={STORY_CRITERIA}
                                        showFullForm={showFullStoryForm}
                                        showFullFormHandler={setShowFullStoryForm}
                                        feedback={storyFeedback}
                                        feedbackHandler={storyFeedbackHandler}
                                        feedbackLength={storyFeedbackLength}
                                        feedbackHeight={storyFeedbackHeight}
                                        modalHandler={storyModalHandler}
                                        type={'story'}
                                    />
                                }
                            </>
                        }

                        {hasAdminRole && <div className='row'>
                            <div className='col-6'>
                                <Select title='Статус:' values={STORY_VALIDATION} value={storyValidation} handler={storyValidationHandler}
                                    disabledSelect={false}
                                />
                            </div>
                            <div className='col-6'>
                                <Select title='Категорія:' values={STORY_CATEGORIES} value={category} handler={categoryHandler}
                                    disabledSelect={storyValidation === '0' || storyValidation === '2'}
                                />
                            </div>
                        </div>}

                        {((userCategoryId && userCategoryId === DREAM_CATEGORY_ID) || hasAdminRole)
                            && <>
                                <h4 className='story-text-title'>
                                    Ідея:
                                </h4>
                                <div className='story-text'>
                                    {story[FIELDS.dream] && transformDreamText(story[FIELDS.dream])}
                                    {story[FIELDS.dream]?.length < DISPLAYED_CHARACTERS_AMOUNT || <button
                                        className='story-text-btn'
                                        hidden={story[FIELDS.dream]?.length < DISPLAYED_CHARACTERS_AMOUNT}
                                        onClick={showFullDreamHandler}>
                                        {(!showFullDream) ? 'читати далі' : 'cховати'}
                                    </button>}
                                </div>
                                {(Object.keys(dreamAssessment).length !== 0)
                                    ? <Criterions assessment={dreamAssessment} criteria={DREAM_CRITERIA} max={50} />
                                    : <Evaluation
                                        title='Ваша оцінка'
                                        criteria={dreamCriteria}
                                        criteriaHandler={dreamCriteriaHandler}
                                        criteriaArray={DREAM_CRITERIA}
                                        showFullForm={showFullDreamForm}
                                        showFullFormHandler={setShowFullDreamForm}
                                        feedback={dreamFeedback}
                                        feedbackHandler={dreamFeedbackHandler}
                                        feedbackLength={dreamFeedbackLength}
                                        feedbackHeight={dreamFeedbackHeight}
                                        modalHandler={dreamModalHandler}
                                        type={'dream'}
                                    />
                                }
                            </>
                        }

                        {hasAdminRole &&
                            <>
                                <Select title='Статус:' values={STORY_VALIDATION} value={dreamValidation} handler={dreamValidationHandler}
                                    disabledSelect={false}
                                />

                                <div className='row'>
                                    <div className='d-flex justify-content-end'>
                                        <button
                                            className='btn w-25 mt-4'
                                            disabled={((category === '0' && storyValidation === '1') || (storyValidation === '0') || (dreamValidation === '0')) && true}
                                            onClick={saveAttribute}>
                                            Зберегти
                                        </button>
                                    </div>
                                </div>
                            </>
                        }
                    </div>

                    <div className='col-3'>
                        {hasAdminRole && <StoryContacts story={story} type={1} />}
                        <Files story={story} />
                    </div>
                </div>
            </main>

            {isLoading && <Loader />}
            {showStoryModal && <Modal send={sendStoryFeedback} cancel={storyModalHandler} />}
            {showDreamModal && <Modal send={sendDreamFeedback} cancel={dreamModalHandler} />}
        </>
    )
}
