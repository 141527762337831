const ALL_CATEGORIES = [
    'Без категорії',
    'Вибір серцем',
    'Вчитель-волонтер',
    'Вчитель-незламність',
    'Вчитель-новатор',
    'Інші цікаві історії',
    'Молодий лідер освіти',
    'Вчитель дошкільної освіти',
    'Ocвітня Ідея'
]

export default ALL_CATEGORIES;