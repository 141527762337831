export default function transformText(text, condition, limit = 400) {
    if (condition) {
        if (text.length > limit) {
            return text.split('\n').map((paragraph, i) => <p key={i}> {paragraph} </p>)
        } else {
            return text.split('\n').map((paragraph, i) => <p key={i}> {paragraph} </p>)
        }
    } else {
        if (text.length > limit) {
            return text.substring(0, 400).split('\n').map((paragraph, i, arr) => {
                return (arr.length - 1 === i) ? <p key={i}> {paragraph + '...'} </p> : <p key={i}> {paragraph} </p>
            });
        } else {
            return text.split('\n').map((paragraph, i) => <p key={i}> {paragraph} </p>);
        }
    }
}