//styles
import './style.css';

//icons
import maximize from '../../assets/icons/maximize.png';

export default function File({ fileName = '' }) {
    const filePath = 'http://globalteacherprize.org.ua/wp-content/uploads/cfdb7_uploads/' + fileName;
    const imageNameExtension = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'tiff', 'tif'];

    let fileNameExtension = fileName.split('.')[fileName.split('.').length - 1];

    return (
        <div className='file-container'>
            {
                (imageNameExtension.includes(fileNameExtension))
                    ? <div className='file-container-image'>
                        <img src={filePath} alt='file-1cfdb7_file' />
                        <a href={filePath} target='_blank' rel='noreferrer'>
                            <img src={maximize} alt='maximize' title='Відкрити в повному розмірі' />
                        </a>
                    </div>
                    : <div className='file-container-file'>
                        <a href={filePath}>Завантажити файл</a>
                    </div>
            }
        </div>
    )
}
