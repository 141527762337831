//components
import File from '../File/File';

//utilities
import decodeHTMLEntities from '../../features/decodeHTMLEntities';

//constans
import FIELDS from '../../app/fields';

//styles
import './style.css';

export default function Files({ story }) {
    return (
        <div className='files'>
            <h3>Матеріали:</h3>
            <div className='files-list'>
                {
                    (story[FIELDS.file1] || story[FIELDS.file2] || story[FIELDS.file2] || story[FIELDS.file2])
                        ? <>
                            {story[FIELDS.file1] && <File fileName={story[FIELDS.file1]} />}
                            {story[FIELDS.file2] && <File fileName={story[FIELDS.file2]} />}
                            {story[FIELDS.file3] && <File fileName={story[FIELDS.file3]} />}
                            {story[FIELDS.file4] && <File fileName={story[FIELDS.file4]} />}
                        </>
                        : <div className='files-empty'>
                            Файлів не додано.
                        </div>
                }
                {
                    story[FIELDS.url] && <div className='files-url'>
                        <a href={decodeHTMLEntities(story[FIELDS.url])} target='_blank' rel='noreferrer'>
                            Відкрити прикріплене покликання
                        </a>
                    </div>
                }
                {
                    story[FIELDS.yourUrl] && <div className='files-url'>
                        <a href={decodeHTMLEntities(story[FIELDS.yourUrl])} target='_blank' rel='noreferrer'>
                            Відкрити прикріплене покликання
                        </a>
                    </div>
                }
            </div>
        </div>

    )
}
