//styles
import './style.css';

export default function Select({ title, value, handler, values, disabledSelect }) {
    return (
        <div className='select-component'>
            <h3 className='select-component-title'>
                {title}
            </h3>
            <div className='select-component-control'>
                <select className='form-select' value={value} onChange={(e) => handler(e)} disabled={disabledSelect}>
                    {
                        values.map((category, index) => {
                            return (
                                <option key={index} className='form-control' value={index}>
                                    {category}
                                </option>
                            )
                        })
                    }
                </select>
            </div>
        </div>

    )
}
