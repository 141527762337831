//styles
import './style.css';

//icons
import sendIcon from '../../assets/icons/send.svg';

export default function CommentInput({ text, handler, length, height, action }) {
    return (
        <div className='comment-input'>
            <label>
                <div className='comment-input-length-wrapper'>
                    <span className='comment-input-length'>
                        {length}/1000
                    </span>
                </div>
                <div>
                    <textarea
                        className='comment-input-textarea'
                        style={{ height: height }}
                        value={text}
                        onChange={handler}
                    />
                </div>
                <div className='comment-input-btn-wrapper'>
                    <button className='comment-input-btn' onClick={(e) => action(e)}>
                        Надіслати <img src={sendIcon} alt='icon' />
                    </button>
                </div>
            </label>
        </div>
    )
}
