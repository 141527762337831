export default function Subtitle({subtitle}) {
    return (
        <div className='row'>
            <div className='col'>
                <h2>
                    {subtitle}
                </h2>
            </div>
        </div>
    )
}
