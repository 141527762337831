import { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import axios from 'axios';

//constants
import SERVER_URL from '../../constans/server-url';
import ATS_POST_FORM_ID from '../../constans/about-teacher-story-post-form-id';

//styles
import './style.css';

export default function StoryNav({ id }) {
    const navigate = useNavigate();

    const [form, setForm] = useState(0);

    useEffect(() => {
        id && axios.post(SERVER_URL + '/api/story/read-story-form-post-id', { id })
            .then((response) => { setForm(response.data.form_post_id) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, navigate]);

    return (
        <div className='story-nav'>
            {(form === ATS_POST_FORM_ID)
                ? <NavLink to={'/about-teacher-story/' + id} className={({ isActive }) => isActive ? 'active' : undefined}>Історія</NavLink>
                : <NavLink to={'/teacher-story/' + id} className={({ isActive }) => isActive ? 'active' : undefined}>Історія</NavLink>
            }
            <NavLink to={'/comments/' + id} className={({ isActive }) => isActive ? 'active' : undefined}>Коментарі адміністраторів</NavLink>
            <NavLink to={'/evaluations/' + id} className={({ isActive }) => isActive ? 'active' : undefined}>Оцінки експертів</NavLink>
        </div>
    )
}
