const ABOUT_TEACHER_STORY_FIELDS = {
    name: 'your-first-name',
    surname: 'your-last-name',
    tel: 'your-tel',
    email: 'your-email',
    city: 'your-city',
    story: 'story',
    message: 'message',
    teacherName: 'teacher-name',
    teacherTel: 'teacher-tel',
    teacherEmail: 'teacher-email',
    story: 'story',
    communicatePermission: 'permission-to-communicate',
    file1: 'file-1cfdb7_file',
    file2: 'file-2cfdb7_file',
    file3: 'file-3cfdb7_file',
    file4: 'file-4cfdb7_file',
}

export default ABOUT_TEACHER_STORY_FIELDS;