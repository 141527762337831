import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import StoriesTable from '../../components/StoriesTable/StoriesTable';
import Loader from '../../components/Loader/Loader';

//states
import { selectCurrentUser } from '../../features/authSlice';

//utilities
import decodeHTMLEntities from '../../features/decodeHTMLEntities';

//constants
import SERVER_URL from '../../constans/server-url';
import MAX_STORY_SCORE from '../../constans/max-story-score';
import MAX_DREAM_SCORE from '../../constans/max-dream-score';

export default function AllStories() {
    const navigate = useNavigate();

    const currentUser = useSelector(selectCurrentUser);

    const [isLoading, setIsLoading] = useState(true);
    const [aboutTeacherStories, setAboutTeacherStories] = useState([]);
    const [teacherStories, setTeacherStories] = useState([]);
    const [assessorsAmount, setAssessorsAmount] = useState({});
    const [showStories, setShowStories] = useState('about-teacher-stories');

    useEffect(() => {
        if (currentUser) {
            setIsLoading(true);
            axios.all([
                axios.get(SERVER_URL + '/api/assessments/read'),
                axios.post(SERVER_URL + '/api/assessments/read-user-assessments', { user_id: currentUser.id }),
                axios.get(SERVER_URL + '/api/assessments/read-assessments-evaluation'),
                axios.get(SERVER_URL + '/api/assessments/read-dream-assessments-evaluation'),
                axios.get(SERVER_URL + '/api/attribute/read-all'),
                axios.post(SERVER_URL + '/api/teacher-stories'),
                axios.post(SERVER_URL + '/api/about-teacher-stories')
            ]).then(axios.spread((data1, data2, data3, data4, data5, data6, data7) => {
                let assessmentsData = data1.data.reduce((obj, current) => ({ ...obj, [current.form_id]: current.form_id_amount }), {});
                let evaluatedStoriesData = data2.data.reduce((obj, current) => ({ ...obj, [current.form_id]: true }), {});

                let evaluationData = {};
                for (let i in data3.data) {
                    evaluationData[i] = JSON.parse('[' + data3.data[i] + ']');
                }

                let evaluationDreamData = {};
                for (let i in data4.data) {
                    evaluationDreamData[i] = JSON.parse('[' + data4.data[i] + ']');
                }

                let result5 = data5.data;
                let assessorsData = {};
                result5.forEach(i => {
                    assessorsData[i.form_id] = {
                        category: i.category,
                        story_validation: i.story_validation,
                        dream_validation: i.dream_validation
                    }
                })

                let { data } = data6;
                let results = data.map(i => {
                    i.data['first-name'] = decodeHTMLEntities(i.data['first-name']);
                    i.data['last-name'] = decodeHTMLEntities(i.data['last-name']);

                    i.evaluation = evaluationData[i.id];
                    i.story_total = i.evaluation && i.evaluation?.reduce((prev, curr) => {
                        return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                    }, 0);

                    i.dream_evaluation = evaluationDreamData[i.id];
                    i.dream_total = i.dream_evaluation && i.dream_evaluation?.reduce((prev, curr) => {
                        return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                    }, 0);

                    if (i.story_total && i.evaluation?.length > 0) {
                        let rate = i.story_total / i.evaluation.length / MAX_STORY_SCORE * 100
                        if (!isNaN(rate)) i.story_rate = rate.toFixed(2);
                    }

                    if (i.dream_total && i.dream_evaluation?.length > 0) {
                        let rate = i.dream_total / i.dream_evaluation.length / MAX_DREAM_SCORE * 100
                        if (!isNaN(rate)) i.dream_rate = rate.toFixed(2);
                    }

                    i.category = assessorsData[i.id]?.category;
                    i.story_validation = assessorsData[i.id]?.story_validation;
                    i.dream_validation = assessorsData[i.id]?.dream_validation;
                    i.evaluatedStories = evaluatedStoriesData[i.id];
                    i.assessments = assessmentsData[i.id] || 0;
                    return i;
                })
                setTeacherStories(results);


                let data0 = data7.data;
                let results0 = data0.map(i => {
                    i.data['your-first-name'] = decodeHTMLEntities(i.data['your-first-name']);
                    i.data['your-last-name'] = decodeHTMLEntities(i.data['your-last-name']);
                    i.evaluation = evaluationData[i.id];

                    i.story_total = i.evaluation && i.evaluation?.reduce((prev, curr) => {
                        return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                    }, 0);

                    i.dream_evaluation = evaluationDreamData[i.id];

                    i.dream_total = i.dream_evaluation && i.dream_evaluation?.reduce((prev, curr) => {
                        return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                    }, 0);

                    if (i.story_total && i.evaluation?.length > 0) {
                        let rate = i.story_total / i.evaluation.length / MAX_STORY_SCORE * 100
                        if (!isNaN(rate)) i.story_rate = rate.toFixed(2);
                    }

                    if (i.dream_total && i.dream_evaluation?.length > 0) {
                        let rate = i.dream_total / i.dream_evaluation.length / MAX_DREAM_SCORE * 100
                        if (!isNaN(rate)) i.dream_rate = rate.toFixed(2);
                    }

                    i.category = assessorsData[i.id]?.category;
                    i.story_validation = assessorsData[i.id]?.story_validation;
                    i.dream_validation = assessorsData[i.id]?.dream_validation;
                    i.evaluatedStories = evaluatedStoriesData[i.id];
                    i.assessments = assessmentsData[i.id] || 0;
                    if (i.type && i.type === 0) {
                        i.eval = i.evaluation?.reduce((prev, curr) => {
                            return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                        }, 0);
                    }
                    return i;
                })
                setAboutTeacherStories(results0);

                setIsLoading(false);
            }))
        }
    }, [currentUser]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/users-with-category')
            .then((response) => {
                let { data } = response;
                let results = {}

                data.forEach(i => {
                    results[`${i.category}`] = i.categoryAmount;
                })
                setAssessorsAmount(results);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    const showStoriesHandler = (type) => {
        setShowStories(type);
    }

    return (
        <>
            <main>
                <div className='row'>
                    <h1>
                        Всі заявки
                    </h1>
                </div>
                <div className='all-stories-control'>
                    <div
                        className={showStories === 'about-teacher-stories' ? 'all-stories-control-active' : ''} 
                        onClick={() => showStoriesHandler('about-teacher-stories')}>
                        <h2>
                            Про вчителів
                        </h2>
                    </div>
                    <div
                        className={showStories === 'teacher-stories' ? 'all-stories-control-active' : ''}  
                        onClick={() => showStoriesHandler('teacher-stories')}>
                        <h2>
                            Від вчителів
                        </h2>
                    </div>
                </div>
                {showStories === 'about-teacher-stories'
                    ? <StoriesTable
                        stories={aboutTeacherStories}
                        type={'about-teacher-stories'}
                        assessorsAmount={assessorsAmount}
                    />
                    : <StoriesTable
                        stories={teacherStories}
                        type={'teacher-stories'}
                        assessorsAmount={assessorsAmount}
                    />
                }
            </main>
            {isLoading && <Loader />}
        </>
    )
}