import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

//components
import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import UserList from '../../components/UserList/UserList';

//services
import authHeader from '../../services/auth-header';

//constans
import SERVER_URL from '../../constans/server-url';
import ALL_CATEGORIES from '../../constans/all-categories';

//styles
import './style.css';

//icons
import addIcon from '../../assets/icons/add.svg';
import closeIcon from '../../assets/icons/close.svg';


export default function AssessorsControlPage() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [allUsers, setAllUsers] = useState([]);
    const [storyAssessors, setStoryAssessors] = useState([]);
    const [storyUsers, setStoryUsers] = useState([]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/users-with-roles', { headers: authHeader() })
            .then((response) => {
                let allUserData = response.data.filter(user => user.roles.length === 1);
                setAllUsers(allUserData);
            })
            .catch((error) => { navigate('/error', { state: { error: error.response.data.message } }) });
    }, [navigate]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/user/users-without-category')
            .then((response) => {
                let { data } = response;
                setStoryUsers(data.filter(user => user.roles.length < 3));
            })
            .catch((error) => { navigate('/error', { state: { error: error.response.data.message } }) });
    }, [id, navigate]);

    useEffect(() => {
        id && axios.post(SERVER_URL + '/api/user/users-with-specific-category', { category: id })
            .then((response) => {
                let { data } = response;
                setStoryAssessors(data);
            })
            .catch((error) => { navigate('/error', { state: { error: error.response.data.message } }) });
    }, [id, navigate]);

    const storyUsersHandler = (user) => {
        let newAssessors = [...storyAssessors, user]
            .sort((a, b) => a.id - b.id);
        setStoryAssessors(newAssessors);
        let newUsers = (storyUsers.filter((item) => item.id !== user.id))
            .sort((a, b) => a.id - b.id);
        setStoryUsers(newUsers);
    }

    const storyAssessorsHandler = (user) => {
        let newUsers = [...storyUsers, user]
            .sort((a, b) => a.id - b.id);
        setStoryUsers(newUsers);
        let newAssessors = (storyAssessors.filter((item) => item.id !== user.id))
            .sort((a, b) => a.id - b.id);
        setStoryAssessors(newAssessors);
    }

    const updateUsersCategories = () => {
        let usersWithCategory = storyAssessors.map(item => item.id)
        let usersWithoutCategory = storyUsers.map(item => item.id)

        id && axios.post(SERVER_URL + '/api/user/update-users-categories',
            {
                users_with_category: usersWithCategory,
                users_without_category: usersWithoutCategory,
                category: id
            })
            .then((response) => {
                if (response.status === 200) {
                    window.location.reload();
                }
            })
            .catch((error) => { navigate('/error', { state: { error: error.response.data.message } }) });
    }

    return (
        <div className='assessors-control-page'>
            <Breadcrumbs url={'/categories'} text={'До всіх категорій'} />
            <div>
                <h1>
                    Експерти категорії "{ALL_CATEGORIES[id]}"
                </h1>
            </div>
            <div className='assessors-control-page-wrapper'>
                <div className='row'>
                    <div className='col-6'>
                        <UserList
                            title='Незадіяні експерти:'
                            users={storyUsers}
                            handler={storyUsersHandler}
                            icon={addIcon}
                        />
                    </div>
                    <div className='col-6'>
                        <UserList
                            title='Задіяні експерти:'
                            users={storyAssessors}
                            handler={storyAssessorsHandler}
                            icon={closeIcon}
                        />
                    </div>
                </div>
                <button className='btn' onClick={updateUsersCategories}>
                    Зберегти
                </button>
            </div>
        </div>
    )
}
