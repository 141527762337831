export default function Select({ title, value, handler, values }) {
    return (
        <div className='row'>
            <div className='col'>
                <h3>
                    {title}
                </h3>
                <div className='control '>
                    <select className='form-control' value={value} onChange={(e) => handler(e)}>
                        {
                            values.map((category, index) => {
                                return (
                                    <option key={index} className='form-control' value={index}>
                                        {category}
                                    </option>
                                )
                            })
                        }
                    </select>
                </div>
            </div>
        </div>
    )
}
