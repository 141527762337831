import { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import Loader from '../../components/Loader/Loader';

//states
import { selectCurrentUser, selectAdminRole, selectModeratorRole } from '../../features/authSlice';

//services
import AuthService from '../../services/auth.service';

//constants
import SERVER_URL from '../../constans/server-url';
import ALL_CATEGORIES from '../../constans/all-categories';
import ABOUT_TEACHER_STORY_FIELDS from '../../constans/about-teacher-story-fields';
import TEACHER_STORY_FIELDS from '../../constans/teacher-story-fields';
import STORY_CATEGORIES from '../../constans/story-categories';
import ATS_POST_FORM_ID from '../../constans/about-teacher-story-post-form-id';
import MAX_STORY_SCORE from '../../constans/max-story-score';
import MAX_DREAM_SCORE from '../../constans/max-dream-score';

//styles
import './style.css';

//icons
import checkIcon from '../../assets/icons/check-green.svg';
import closeIcon from '../../assets/icons/close-red.svg';
import indeterminateIcon from '../../assets/icons/indeterminate-grey.svg';
import STORY_SETTINGS from '../../constans/story-settings';

export default function CategoryPage() {
    const STORY_CATEGORIES_DESCRIPTION = [
        'Історії без категорії.',
        'Наталія Мосейчук, кураторка Global Teacher Prize Ukraine та телеведуча 1+1 відзначає внесок вчителів, які з великою любов\'ю та розумінням працюють з дітьми з інвалідністю та тими, хто знаходиться в лікарні за допомогою окремої номінації "Вибір серцем".',
        'Відзначаємо вчителів, які працюють не тільки в класах, а й знаходять час для волонтерства.',
        'Відзначаємо працю вчителів, які відбудовують освіту на деокупованих територіях, викладають у прифронтових зонах, або зберігають українську освіту під окупацією.',
        'Вчителі, що знаходять нестандартні формати викладання та відкриті до проактивних змін у навчальному процесі (як варіант: ведуть кроссекторальні проєкти = STEM).',
        'Інші історії, варті уваги.',
        'Категорія за виявлення потенціалу в молодому педагогу та сприяння його розвитку.',
        'Категорія "Вчитель дошкільної освіти під час війни" визнає величезний внесок вчителя дошкільної освіти, який здійснює свою роботу в умовах конфлікту чи воєнного стану. Ця номінація відзначає вчителя, який здатний забезпечити безпеку, підтримку і освіту маленьких дітей в надзвичайних умовах.',
        'Якби у вас був мільйон гривень, який проєкт ви б реалізували для відбудови освіти на деокупованих землях?',
    ];

    const POINTS = [MAX_STORY_SCORE, MAX_DREAM_SCORE];

    const hasAdminRole = useSelector(selectAdminRole);
    const hasModeratorRole = useSelector(selectModeratorRole);

    const navigate = useNavigate();
    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(false);
    const [user, setUser] = useState(AuthService.getCurrentUser());
    const [userCategoryId, setUserCategoryId] = useState(undefined);
    const [formsId, setFormsId] = useState([]);

    const [stories, setStories] = useState([]);
    const [adminStories, setAdminStories] = useState([]);
    const [moderatorStories, setModeratorStories] = useState([]);
    const [evaluations, setEvaluations] = useState([]);

    const [assessorsAmount, setAssessorsAmount] = useState({});
    const [moderatorValidations, setModeratorValidations] = useState({});

    const [assessors, setAssessors] = useState([]);
    const [moderators, setModerators] = useState([]);
    const [moderatorId, setModeratorId] = useState(undefined);
    const [moderatorEvaluations, setModeratorEvaluations] = useState({});

    const [type, setType] = useState(0);

    useEffect(() => {
        !id && user && axios.post(SERVER_URL + '/api/user/read-user-category', { userId: user.id })
            .then((response) => {
                let result = response.data.category;
                if (result !== null) {
                    setUserCategoryId((+response.data.category).toString())
                } else {
                    setUserCategoryId('0');
                }
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, user, navigate]);

    useEffect(() => {
        const categoryId = userCategoryId || id;

        if (categoryId && categoryId !== '8' && categoryId !== '0') {
            axios.post(SERVER_URL + '/api/attribute/read-forms-id-by-category', { id: categoryId })
                .then((response) => { setFormsId(response.data) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        } else if (categoryId && categoryId === '8') {
            axios.get(SERVER_URL + '/api/attribute/read-dreams-forms-id')
                .then((response) => { setFormsId(response.data) })
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, userCategoryId, navigate]);

    useEffect(() => {
        formsId.length > 0 && axios.post(SERVER_URL + '/api/stories/read-stories-by-forms-id', { formsId: formsId })
            .then((response) => { setStories(response.data); setIsLoading(false) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [formsId, navigate])

    useEffect(() => {
        const categoryId = userCategoryId || id;
        if (categoryId === '0') return false;
        const type = (categoryId === '8') ? 1 : 0;
        setType(type);

        user && axios.post(SERVER_URL + '/api/assessments/read-user-assessments-by-type', {
            user_id: user.id,
            type: type
        })
            .then((response) => {
                let { data } = response;
                let results = {};
                data.forEach(i => {
                    results[i.form_id] = {
                        status: true,
                        evaluation: JSON.parse(i.evaluation)
                    };
                });

                setEvaluations(results);

            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, userCategoryId, user, navigate]);

    useEffect(() => {
        hasAdminRole && axios.all([
            axios.get(SERVER_URL + '/api/assessments/read-assessments-evaluation'),
            axios.get(SERVER_URL + '/api/assessments/read-dream-assessments-evaluation')
        ]).then(axios.spread((data1, data2) => {
            let evaluationStoryData = {};
            for (let i in data1.data) {
                evaluationStoryData[i] = JSON.parse('[' + data1.data[i] + ']');
            }

            let evaluationDreamData = {};
            for (let i in data2.data) {
                evaluationDreamData[i] = JSON.parse('[' + data2.data[i] + ']');
            }

            let result = stories.map(story => {
                story.story_evaluation = evaluationStoryData[story.id];
                story.story_total = story.story_evaluation?.reduce((prev, curr) => {
                    return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                }, 0);

                story.dream_evaluation = evaluationDreamData[story.id];
                story.dream_total = story.dream_evaluation?.reduce((prev, curr) => {
                    return prev + Object.values(curr).reduce((prev, curr) => prev + +curr, 0)
                }, 0);

                if (story.story_total && story.story_evaluation?.length > 0) {
                    let rate = story.story_total / story.story_evaluation.length / MAX_STORY_SCORE * 100
                    if (!isNaN(rate)) story.story_rate = rate.toFixed(2);
                }
                story.story_rate_number = (story.story_rate) ? +story.story_rate : 0;

                if (story.dream_total && story.dream_evaluation?.length > 0) {
                    let rate = story.dream_total / story.dream_evaluation.length / MAX_DREAM_SCORE * 100
                    if (!isNaN(rate)) story.dream_rate = rate.toFixed(2);
                }
                story.dream_rate_number = (story.dream_rate) ? +story.dream_rate : 0;

                return story;
            });

            let filteredResult = [];

            if (id === '8' || id === 8) {
                filteredResult = result.sort((a, b) => b.dream_rate_number - a.dream_rate_number)
            } else {
                filteredResult = result.sort((a, b) => +b.story_rate_number - +a.story_rate_number)
            }

            setAdminStories(filteredResult);
        }))
    }, [id, hasAdminRole, stories]);

    useEffect(() => {
        hasAdminRole && axios.get(SERVER_URL + '/api/users-with-category')
            .then((response) => {
                let { data } = response;
                let results = {}

                data.forEach(i => {
                    results[`${i.category}`] = i.categoryAmount;
                })
                setAssessorsAmount(results);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [hasAdminRole, navigate]);

    useEffect(() => {
        const ApiURL = (id === '8' || userCategoryId === '8')
            ? '/api/attribute/read-moderator-dream-validations'
            : '/api/attribute/read-moderator-story-validations'

        hasModeratorRole && axios.get(SERVER_URL + ApiURL)
            .then((response) => {
                setModeratorValidations(response.data);
                let results = stories
                    .map(story => {
                        story.moderatorValidation = response.data[story.id] || '0'
                        return story;
                    })
                    .filter(story => story.moderatorValidation === '1');

                setModeratorStories(results);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [id, userCategoryId, hasModeratorRole, stories, navigate]);

    useEffect(() => {
        const categoryId = userCategoryId || id;
        categoryId && axios.post(SERVER_URL + '/api/user/users-with-specific-category',
            {
                category: categoryId
            })
            .then((response) => {
                let { data } = response;
                setAssessors(data.filter(user => user.roles.length === 1));
                setModerators(data.filter(user => user.roles.length === 2));
                setModeratorId(data.filter(user => user.roles.length === 2)[0]?.id);
            }
            )
            .catch((error) => { 
                // navigate('/error', { state: { error: error.response.data.message } })
                console.log(error);
            });
    }, [id, userCategoryId, navigate]);


    const updateModeratorValidation = (e, formId) => {
        setIsLoading(true);

        const ApiURL = (id === '8' || userCategoryId === '8' || id === 8 || userCategoryId === 8)
            ? '/api/attribute/update-moderator-dream-validation'
            : '/api/attribute/update-moderator-story-validation';

        const moderatorValidation = +e.target.checked;
        const newModeratorValidation = String(+e.target.checked);
        let obj = { ...moderatorValidations, [formId]: newModeratorValidation };

        axios.post(SERVER_URL + ApiURL, {
            form_id: formId,
            moderator_validation: moderatorValidation
        })
            .then((response) => {
                setModeratorValidations(obj);
                setIsLoading(false)
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }

    useEffect(() => {
        const categoryId = userCategoryId || id;
        if (categoryId === '0') return false;
        const type = (categoryId === '8' || categoryId === 8) ? 1 : 0;

        hasAdminRole && moderatorId && axios.post(SERVER_URL + '/api/assessments/read-all-user-assessments-by-type', {
            user_id: moderatorId,
            type: type
        })
            .then((response) => {
                let { data } = response;
                let results = {};
                data.forEach(i => {
                    if (type === 1) {
                        results[i.form_id] = {
                            total: (Object.values(JSON.parse(i.evaluation))?.reduce((prev, cur) => prev + +cur, 0) / MAX_DREAM_SCORE * 100).toFixed(2)
                        };
                    } else {
                        results[i.form_id] = {
                            total: (Object.values(JSON.parse(i.evaluation))?.reduce((prev, cur) => prev + +cur, 0) / MAX_STORY_SCORE * 100).toFixed(2)
                        };
                    }
                });
                setModeratorEvaluations(results);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [hasAdminRole, id, userCategoryId, moderatorId, navigate]);

    return (
        <>
            <main className='category-page'>
                {(userCategoryId !== '0' || id !== '0') &&
                    <>
                        <h1>{ALL_CATEGORIES[id] || ALL_CATEGORIES[userCategoryId]}</h1>
                        <p className='category-page-description'>
                            {STORY_CATEGORIES_DESCRIPTION[id] || STORY_CATEGORIES_DESCRIPTION[userCategoryId]}
                        </p>
                    </>
                }
                {(!hasAdminRole && !hasModeratorRole)
                    && <>
                        <table className='table category-page-table'>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Відправник</th>
                                    <th>Статус</th>
                                    <th>Ваша оцінка</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    stories.length === 0
                                    && <tr>
                                        <td colSpan='10'>
                                            Завантаження даних...
                                        </td>
                                    </tr>
                                }
                                {stories?.map(story => {
                                    return (
                                        <tr key={story.id} style={user?.email && STORY_SETTINGS[user.email] && STORY_SETTINGS[user.email].includes(Number(story.id)) ? {} : {'display': 'none'}}>
                                            <td>{story.id}</td>
                                            <td>
                                                {
                                                    (story.form === ATS_POST_FORM_ID)
                                                        ? `${story.data[ABOUT_TEACHER_STORY_FIELDS.name]} ${story.data[ABOUT_TEACHER_STORY_FIELDS.surname]}`
                                                        : `${story.data[TEACHER_STORY_FIELDS.name]} ${story.data[TEACHER_STORY_FIELDS.surname]}`
                                                }
                                            </td>
                                            <td>
                                                {(evaluations[story.id]?.status)
                                                    ? <img src={checkIcon} alt='icon' />
                                                    : <img src={indeterminateIcon} alt='icon' />}
                                            </td>
                                            <td>
                                                {(evaluations[story.id]?.evaluation)
                                                    ? (Object.values(evaluations[story.id]?.evaluation)?.reduce((prev, cur) => {
                                                        return prev + +cur;
                                                    }, 0) / POINTS[type] * 100).toFixed(2) + '/100'
                                                    : <img src={indeterminateIcon} alt='icon' />
                                                }
                                            </td>
                                            <td>
                                                <Link
                                                    className='btn btn-primary'
                                                    to={((story.form === ATS_POST_FORM_ID) ? '/about-teacher-story/' : '/teacher-story/') + story.id}>
                                                    {(evaluations[story.id]?.status) ? 'Переглянути' : 'Оцінити'}
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>

                }
                {(!hasAdminRole && hasModeratorRole)
                    && <>
                        <table className='table category-page-table'>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Відправник</th>
                                    <th>Статус</th>
                                    <th>Ваша оцінка</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    moderatorStories.length === 0
                                    && <tr>
                                        <td colSpan='10'>
                                            Заявок не додано.
                                        </td>
                                    </tr>
                                }
                                {moderatorStories?.map(story => {
                                    return (
                                        <tr key={story.id}>
                                            <td>{story.id}</td>
                                            <td>
                                                {
                                                    (story.form === ATS_POST_FORM_ID)
                                                        ? `${story.data[ABOUT_TEACHER_STORY_FIELDS.name]} ${story.data[ABOUT_TEACHER_STORY_FIELDS.surname]}`
                                                        : `${story.data[TEACHER_STORY_FIELDS.name]} ${story.data[TEACHER_STORY_FIELDS.surname]}`
                                                }
                                            </td>
                                            <td>
                                                {(evaluations[story.id]?.status)
                                                    ? <img src={checkIcon} alt='icon' />
                                                    : <img src={indeterminateIcon} alt='icon' />}
                                            </td>
                                            <td>
                                                {(evaluations[story.id]?.evaluation)
                                                    ? (Object.values(evaluations[story.id]?.evaluation)?.reduce((prev, cur) => {
                                                        return prev + +cur;
                                                    }, 0) / POINTS[type] * 100).toFixed(2) + '/100'
                                                    : <img src={indeterminateIcon} alt='icon' />
                                                }
                                            </td>
                                            <td>
                                                <Link
                                                    className='btn btn-primary'
                                                    to={((story.form === ATS_POST_FORM_ID) ? '/about-teacher-story/' : '/teacher-story/') + story.id}>
                                                    {(evaluations[story.id]?.status) ? 'Переглянути' : 'Оцінити'}
                                                </Link>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                    </>

                }
                {
                    (hasAdminRole)
                    && <>
                        <div className='category-page-moderators-list'>
                            <b>Експерт:</b> {
                                moderators.length > 0
                                    ? moderators.map(moderator => `${moderator.username} (${moderator.email})`)
                                    : 'не призначено.'
                            }
                        </div>
                        <div className='category-page-assessors-list'>
                            <b>Амбасадори:</b> {
                                assessors.length > 0
                                    ? assessors.map((assessor, index) => {
                                        let str = `${assessor.username} (${assessor.email})`;
                                        return (assessors.length === index + 1) ? str : str + ', ';
                                    })
                                    : 'не призначено.'
                            }
                        </div>
                        <table className='table category-page-table category-page-table-admin'>
                            <thead>
                                <tr>
                                    <th>№</th>
                                    <th>Відправник</th>
                                    {
                                        (id === '8' || userCategoryId === '8')
                                            ? <th>Середній бал ідеї</th>
                                            : <th>Середній бал історії</th>
                                    }
                                    {
                                        (id === '8' || userCategoryId === '8')
                                            ? <th>Оцінок ідеї</th>
                                            : <th>Оцінок історії</th>
                                    }
                                    <th>Оцінюється експертом</th>
                                    <th>Бал експерта</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    adminStories.length === 0
                                    && <tr>
                                        <td colSpan='10'>
                                            Заявок не додано.
                                        </td>
                                    </tr>
                                }
                                {adminStories?.map(story => {
                                    return (
                                        <tr key={story.id}>
                                            <td>{story.id}</td>
                                            <td>
                                                {
                                                    (story.form === ATS_POST_FORM_ID)
                                                        ? `${story.data[ABOUT_TEACHER_STORY_FIELDS.name]} ${story.data[ABOUT_TEACHER_STORY_FIELDS.surname]}`
                                                        : `${story.data[TEACHER_STORY_FIELDS.name]} ${story.data[TEACHER_STORY_FIELDS.surname]}`
                                                }
                                            </td>
                                            {
                                                (id === '8' || userCategoryId === '8')
                                                    ? <td>
                                                        {story.dream_rate || <img src={indeterminateIcon} alt='icon' />} / 100
                                                    </td>
                                                    : <td>
                                                        {story.story_rate || <img src={indeterminateIcon} alt='icon' />} / 100
                                                    </td>
                                            }
                                            {
                                                (id === '8' || userCategoryId === '8')
                                                    ? <td>
                                                        {(story.dream_evaluation?.length || 0) + '/' + ((assessorsAmount[id] || assessorsAmount[userCategoryId]) || 0)}
                                                    </td>
                                                    : <td>
                                                        {(story.story_evaluation?.length || 0) + '/' + ((assessorsAmount[id] || assessorsAmount[userCategoryId]) || 0)}
                                                    </td>
                                            }
                                            <td>
                                                <label className='checkbox-container'>
                                                    <input
                                                        type='checkbox'
                                                        checked={(moderatorValidations[story.id] === '1') ? true : false}
                                                        onChange={(e) => updateModeratorValidation(e, story.id)} />
                                                    <span className='checkmark'></span>
                                                </label>
                                            </td>
                                            <td>
                                                {moderatorEvaluations[story.id]?.total || <img src={indeterminateIcon} alt='icon' />} / 100
                                            </td>
                                            <td>
                                                <Link
                                                    className='btn category-page-button'
                                                    to={((story.form === ATS_POST_FORM_ID) ? '/about-teacher-story/' : '/teacher-story/') + story.id}>
                                                    Читати
                                                </Link>
                                            </td>
                                        </tr>
                                    )

                                })}
                            </tbody>
                        </table>
                    </>
                }
            </main>
            {isLoading && <Loader />}
        </>
    )
}
