//styles
import './style.css';

export default function UserList({ title, users, handler, icon }) {

    const ROLES = [
        'Амбасадор',
        'Експерт',
        'Адміністратор'
    ];

    return (
        <div className='user-list'>
            <h2>
                {title}
            </h2>
            <div>
                {
                    (users.length > 0)
                        ? users.map((user, index) => {
                            return (
                                <div key={index}>
                                    <button onClick={() => handler(user)}>
                                        <span>{user.username} ({user.email}) - {ROLES[user.roles.length - 1]}</span>
                                        <img src={icon} alt='icon' />
                                    </button>


                                </div>
                            )
                        })
                        : <p>Користувачів не знайдено</p>
                }
            </div>
        </div>
    )
}
