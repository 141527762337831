//constants
import ABOUT_TEACHER_STORY_FIELDS from '../../constans/about-teacher-story-fields';
import TEACHER_STORY_FIELDS from '../../constans/teacher-story-fields';

//styles
import './style.css';

//icons
import userIcon from '../../assets/icons/user.svg';
import phoneIcon from '../../assets/icons/phone.svg';
import mailIcon from '../../assets/icons/mail.svg';

export default function StoryContacts({ story, type }) {
    if (type === 0) {
        return (
            <div className='story-contacts'>
                <h3>Контактні дані:</h3>
                <div>
                    <h4>Вчитель:</h4>
                    <p>
                        <img src={userIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.teacherName] || 'не вказано'}
                    </p>
                    <p>
                        <img src={phoneIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.teacherTel] || 'не вказано'}
                    </p>
                    <p>
                        <img src={mailIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.teacherEmail] || 'не вказано'}
                    </p>
                </div>
                <div>
                    <h4>Відправник:</h4>
                    <p>
                        <img src={userIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.name]}
                    </p>
                    <p>
                        <img src={phoneIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.tel]}
                    </p>
                    <p>
                        <img src={mailIcon} alt='icon' />
                        {story[ABOUT_TEACHER_STORY_FIELDS.email]}
                    </p>
                </div>
            </div>
        )
    } else {
        return (
            <div className='story-contacts'>
                <h3>Контактні дані</h3>
                <div>
                    <p>
                        <img src={phoneIcon} alt='icon' />
                        {story[TEACHER_STORY_FIELDS.tel] || 'не вказано'}
                    </p>
                    <p>
                        <img src={mailIcon} alt='icon' />
                        {story[TEACHER_STORY_FIELDS.email] || 'не вказано'}
                    </p>
                </div>
            </div>
        )
    }
}
