import { useState } from 'react';
import { useSelector } from 'react-redux';

//components
import Feedback from '../Feedback/Feedback';

//states
import { selectAdminRole } from '../../features/authSlice';

//styles
import './style.css';

//icons
import arrow from '../../assets/icons/arrow-down.svg';

export default function Criterions({ criteria, assessment, max }) {
    const hasAdminRole = useSelector(selectAdminRole);

    const [showFullAssessmentEvaluation, setShowFullAssessmentEvaluation] = useState(false);
    const [showFullAssessmentFeedback, setShowFullAssessmentFeedback] = useState(false);

    const showFullAssessmentEvaluationHandler = () => {
        setShowFullAssessmentEvaluation((prevState) => !prevState);
    }

    const showFullAssessmentFeedbackHandler = () => {
        setShowFullAssessmentFeedback((prevState) => !prevState);
    }

    return (
        <div className='criterions'>
            <div onClick={showFullAssessmentEvaluationHandler}>
                <h3>
                    {
                        (hasAdminRole)
                            ? assessment.username + ` (${assessment.email})`
                            : 'Ваше оцінювання'
                    }
                    <img className={showFullAssessmentEvaluation ? 'criterions-arrow-rotate' : 'criterions-arrow'} src={arrow} alt='arrow' />
                </h3>
                <div>
                    {(((Object.values(assessment.evaluation)).reduce((prev, curr) => {
                        return prev + +curr
                    }, 0)) / max * 100).toFixed(2)}% / 100%
                </div>
            </div>
            <div className={showFullAssessmentEvaluation ? 'criterions-content' : 'criterions-content-hidden'}>
                <div>
                    {
                        criteria.map((item, index) => {
                            return (
                                <div className='criteria' key={index}>
                                    <h4> {item.title}: </h4>
                                    <div>
                                        <div> {item.description} </div>
                                        <div> {assessment.evaluation[index]} / 10</div>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>

                <Feedback
                    text={assessment.feedback}
                    showFullText={showFullAssessmentFeedback}
                    handler={showFullAssessmentFeedbackHandler}
                    limit={400}
                />
            </div>
        </div>
    )
}
