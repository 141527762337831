import { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';

//components
import Subtitle from './components/Subtitle/Subtitle';
import Select from './components/Select/Select';
import Loader from '../../components/Loader/Loader';

//constans
import SERVER_URL from '../../constans/server-url';
import STORY_VALIDATION from '../../constans/story-validation';
import STORY_CATEGORIES from '../../constans/story-categories';

//styles
import './style.css';

export default function StoryControl() {
    const navigate = useNavigate();

    const { id } = useParams();

    const [isLoading, setIsLoading] = useState(true);

    const [category, setCategory] = useState('0');
    const [storyValidation, setStoryValidation] = useState('0');
    const [dreamValidation, setDreamValidation] = useState('0');

    useEffect(() => {
        if (id) {
            axios.post(SERVER_URL + '/api/attribute/read', {
                form_id: id
            })
                .then(
                    (response) => {
                        let storyValidation = response.data.story_validation;
                        setStoryValidation(storyValidation);

                        let dreamValidation = response.data.dream_validation;
                        setDreamValidation(dreamValidation);

                        let category = response.data.category;
                        setCategory(category);

                        setIsLoading(false);
                    }
                )
                .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
        }
    }, [id, navigate]);

    const saveAttribute = () => {
        axios.post(SERVER_URL + '/api/attribute/create', {
            form_id: id,
            story_validation: storyValidation,
            dream_validation: dreamValidation,
            category: category
        })
            .then(
                (response) => {
                    if (response.status === 200) {
                        window.location.reload();
                    } else {
                        console.log(response.status);
                    }
                }
            )
    }

    const categoryHandler = (e) => {
        setCategory(e.target.value);
    }

    const storyValidationHandler = (e) => {
        setStoryValidation(e.target.value);
    }

    const dreamValidationHandler = (e) => {
        setDreamValidation(e.target.value);
    }

    return (
        <div className='container'>
            <div className='story-control'>
                <div className='row'>
                    <div className='col'>
                        <h1>
                            Налаштування оцінювання
                        </h1>
                    </div>
                </div>
                <Subtitle subtitle='Технічна екпертиза історії' />
                <Select title='Статус:' values={STORY_VALIDATION} value={storyValidation} handler={storyValidationHandler} />

                <Subtitle subtitle='Категорія історії' />
                <Select title='Категорія:' values={STORY_CATEGORIES} value={category} handler={categoryHandler} />

                <Subtitle subtitle='Технічна екпертиза освіт' />
                <Select title='Статус:' values={STORY_VALIDATION} value={dreamValidation} handler={dreamValidationHandler} />

                <div className='row'>
                    <div className='d-flex justify-content-end'>
                        <button className='btn w-25 mt-4' disabled={category === '0' && true} onClick={saveAttribute}>
                            Зберегти
                        </button>
                    </div>
                </div>
            </div>

            {isLoading && <Loader />}
        </div>
    )
}