import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import axios from 'axios';

//components
import CategoryCard from '../../components/CategoryCard/CategoryCard';
import Loader from '../../components/Loader/Loader';

//constants
import SERVER_URL from '../../constans/server-url';
import STORY_CATEGORIES from '../../constans/story-categories';
import DREAM_CATEGORY_ID from '../../constans/dream-category-id';

//styles
import './style.css';

export default function CategoriesPage() {
    const navigate = useNavigate();

    const [isLoading, setIsLoading] = useState(true);

    const [storiesAmount, setStoriesAmount] = useState(undefined);
    const [attributesAmount, setAttributesAmount] = useState(undefined);
    const [assessorsAmount, setAssessorsAmount] = useState({});
    const [unpassedAmount, setUnpassedAmount] = useState(undefined);
    const [formsByCategory, setFormsByCategory] = useState({});

    useEffect(() => {
        axios.get(SERVER_URL + '/api/stories/amount')
            .then((response) => {
                let { data } = response;
                let storiesAmount = (Object.values(data)).reduce((prev, cur) => {
                    return prev + +cur;
                }, 0)
                setStoriesAmount(storiesAmount);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/attribute/read-attribute-amount')
            .then((response) => { setAttributesAmount(response.data[0].amount) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/users-with-category')
            .then((response) => {
                let { data } = response;
                let results = {}

                data.forEach(i => {
                    results[`${i.category}`] = i.categoryAmount;
                })
                setAssessorsAmount(results);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/attribute/forms-id')
            .then((response) => { setFormsByCategory(response.data) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/attribute/read-not-passed-attributes-amount')
            .then((response) => { setUnpassedAmount(response.data[0].amount) })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [navigate]);

    useEffect((() => {
        storiesAmount
            && attributesAmount
            && unpassedAmount
            && setIsLoading(false);
    }), [storiesAmount, attributesAmount, unpassedAmount]);

    return (
        <>
            <main className='categories-page'>
                <div className='row'>
                    <div className='col-4 categories-left'>
                        <CategoryCard
                            type={'inactive'}
                            category={'Не перевірені'}
                            amount={storiesAmount - attributesAmount} />
                        <CategoryCard
                            type={'inactive'}
                            category={'Відхилені'}
                            amount={unpassedAmount} />
                        <div>
                            <Link className='btn' to={'/stories'}>
                                Всі заявки - {storiesAmount}
                            </Link>
                        </div>
                    </div>
                    <div className='col-8 categories-right'>
                        <CategoryCard
                            type={'dream'}
                            category={'dream'}
                            id={DREAM_CATEGORY_ID}
                            assessors={assessorsAmount[DREAM_CATEGORY_ID]} />

                        {STORY_CATEGORIES.map((category, index) => {
                            if (index !== 0) {
                                return (
                                    <CategoryCard
                                        key={'category' + index}
                                        type={'story'}
                                        category={category}
                                        id={index}
                                        assessors={assessorsAmount[index]}
                                        forms={formsByCategory[index]} />
                                )
                            }
                            return undefined;
                        })}
                    </div>
                </div>
            </main>
            {isLoading && <Loader />}
        </>
    )
}
