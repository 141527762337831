import { useState, useEffect } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

//components
import CommentsModal from '../../components/CommentsModal/CommentsModal';

//states
import { selectAdminRole } from '../../features/authSlice';

//constans
import SERVER_URL from '../../constans/server-url';
import STORY_CATEGORIES from '../../constans/story-categories';

//styles
import './style.css';

//icons
import checkIcon from '../../assets/icons/check-green.svg';
import closeIcon from '../../assets/icons/close-red.svg';
import indeterminateIcon from '../../assets/icons/indeterminate-grey.svg';

export default function UnpassedStoriesPage() {
    const navigate = useNavigate();

    const hasAdminRole = useSelector(selectAdminRole);
    const [showModal, setShowModal] = useState(false);

    const [passedStories, setPassedStories] = useState([]);
    const [unpassedStories, setUnpassedStories] = useState([]);
    const [unpassedStoriesIds, setUnpassedStoriesIds] = useState([]);
    const [stories, setStories] = useState([]);
    const [comments, setComments] = useState([]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/attribute/read-not-passed-attributes')
            .then(response => {
                let array = response.data?.map((story) => {
                    return story.form_id;
                })
                setPassedStories(array);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, []);

    useEffect(() => {
        passedStories.length > 0 && axios.post(SERVER_URL + '/api/stories/read-stories-by-forms-id', { formsId: passedStories })
            .then((response) => {
                setUnpassedStories(response.data);
                let array = response.data?.map((story) => {
                    return story.id;
                })
                setUnpassedStoriesIds(array);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [passedStories, navigate]);

    useEffect(() => {
        unpassedStoriesIds.length > 0 && axios.post(SERVER_URL + '/api/attribute/read-attributes-by-ids', { ids: unpassedStoriesIds })
            .then((response) => {
                let data = response.data;
                let obj = {};
                data.forEach(user => {
                    obj[user.form_id] = {
                        story_validation: user.story_validation,
                        dream_validation: user.dream_validation,
                        category: user.category
                    }
                })

                let result = unpassedStories.map(story => {
                    story.attributes = obj[story.id];
                    return story;
                })
                setStories(result);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }, [unpassedStoriesIds, unpassedStories, navigate]);

    const readComments = (storyId) => {
        axios.post(SERVER_URL + '/api/comment/read-all', {
            'form_id': storyId
        })
            .then((response) => {
                let { data } = response;

                let result = data.map(item => {
                    item.date = (new Date(item.date)).toLocaleString('UK');
                    return item;
                });
                setComments(result);
                setShowModal(true);
            })
            .catch(error => navigate('/error', { state: { error: error.response.data.message } }));
    }

    const closeCommentsModal = () => {
        setComments([]);
        setShowModal(false);
    }

    return (
        <>
            <main className='unpassed-stories-page'>
                <div className='unpassed-stories-page-title'>
                    <h1> Відхилені заявки</h1>
                    <div>{stories.length}</div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Тип</th>
                            <th>Історія</th>
                            <th>Ідея</th>
                            <th>Категорія</th>
                            <th>Коментарі</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stories.map(story => {
                            return (
                                <tr key={story.id}>
                                    <td>{story.id}</td>
                                    <td>
                                        {(story.form === 3520)
                                            ? 'Власна історія вчителя'
                                            : 'Історія про вчителя'
                                        }
                                    </td>
                                    <td>
                                        {(story.attributes === undefined
                                            || story.attributes?.story_validation === null
                                            || story.attributes?.story_validation === 0)
                                            ? <img src={indeterminateIcon} alt='icon' />
                                            : (story.attributes?.story_validation === 1)
                                                ? <img src={checkIcon} alt='icon' />
                                                : <img src={closeIcon} alt='icon' />
                                        }
                                    </td>
                                    <td>
                                        {(story.attributes === undefined
                                            || story.attributes?.dream_validation === null
                                            || story.attributes?.dream_validation === 0)
                                            ? <img src={indeterminateIcon} alt='icon' />
                                            : (story.attributes?.dream_validation === 1)
                                                ? <img src={checkIcon} alt='icon' />
                                                : <img src={closeIcon} alt='icon' />
                                        }
                                    </td>
                                    <td>{(story.attributes === undefined
                                        || story.attributes.category === null)
                                        ? <img src={indeterminateIcon} alt='icon' />
                                        : STORY_CATEGORIES[story.attributes.category]
                                    }</td>
                                    <td>
                                        <button className='btn read-comments-btn' onClick={() => readComments(story.id)}>
                                           Переглянути
                                        </button>
                                    </td>
                                    <td>
                                        {(story.form === 3520)
                                            ? <Link className='btn' to={'/teacher-story/' + story.id}>Читати</Link>
                                            : <Link className='btn' to={'/about-teacher-story/' + story.id}>Читати</Link>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </main>
            {showModal && <CommentsModal comments={comments} closeHandler={closeCommentsModal} />}
        </>
    )
}
