//styles
import './style.css';

export default function FeedbackInput({ feedback, handler, length, height }) {
    return (
        <div className='feedback'>
            <label>
                <h4 className='feedback-title'>
                    Аргументуйте свою оцінку:
                </h4>
                <div>
                    <span className='feedback-length'>
                        {length}/1000
                    </span>
                    <textarea
                        className='form-control feedback-textarea'
                        style={{ height: height }}
                        value={feedback}
                        onChange={handler}
                    />
                </div>
            </label>
        </div>
    )
}
