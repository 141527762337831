//styles
import './style.css';

export default function Modal({ send, cancel }) {
    return (
        <div className='modal-container'>
            <div className='modal-window'>
                <h1>
                    Ви впевнені?
                </h1>
                <p>
                    У Вас є лише 1 спроба залишити оцінку. Будь ласка, переконайтесь, що Ви все правильно проставили і написали.
                </p>
                <div className='row'>
                    <div className='col-6'>
                        <button className='btn' onClick={(e) => cancel(e)}>
                            Скасувати
                        </button>
                    </div>
                    <div className='col-6'>
                        <button className='btn' onClick={send}>
                            Відправити
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}
