const FIELDS = {
    city: 'your-city',
    story: 'story',
    message: 'message',
    teacherName: 'teacher-name',
    file1: 'file-1cfdb7_file',
    file2: 'file-2cfdb7_file',
    file3: 'file-3cfdb7_file',
    file4: 'file-4cfdb7_file',
    url: 'url',
    yourUrl: 'your-url'
}

export default FIELDS;