import React, { useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';

//states
import { selectAdminRole } from '../../features/authSlice';

//validation
import Form from 'react-validation/build/form';
import Input from 'react-validation/build/input';
import CheckButton from 'react-validation/build/button';
import { isEmail } from 'validator';

import AuthService from '../../services/auth.service';

const required = (value) => {
  if (!value) {
    return (
      <div className='alert alert-danger' role='alert'>
        Це поле обов'язкове!
      </div>
    );
  }
};

const validEmail = (value) => {
  if (!isEmail(value)) {
    return (
      <div className='alert alert-danger' role='alert'>
        Невірний формат електронної адреси!
      </div>
    );
  }
};

const vusername = (value) => {
  if (value.length < 3 || value.length > 20) {
    return (
      <div className='alert alert-danger' role='alert'>
       Логін повинен бути від 3 до 20 символів!
      </div>
    );
  }
};

const vpassword = (value) => {
  if (value.length < 6 || value.length > 40) {
    return (
      <div className='alert alert-danger' role='alert'>
        Пароль повинен бути від 6 до 40 символів!
      </div>
    );
  }
};

export default function Register() {
  const navigate = useNavigate();

  const form = useRef();
  const checkBtn = useRef();

  const hasAdminRole = useSelector(selectAdminRole);

  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [roles, setRoles] = useState(['user']);
  const [successful, setSuccessful] = useState(false);
  const [message, setMessage] = useState('');

  const onChangeUsername = (e) => {
    const username = e.target.value;
    setUsername(username);
  };

  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };

  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };

  const onChangeRole = (e) => {
    const role = e.target.value;
    switch (role) {
      case 'user':
        setRoles(['user']);
        break;
      case 'moderator':
        setRoles(['user', 'moderator']);
        break;
      case 'admin':
        setRoles(['user', 'moderator', 'admin']);
        break;
      default:
        setRoles(['user'])
    }
  };

  const handleRegister = (e) => {
    e.preventDefault();

    setMessage("");
    setSuccessful(false);

    form.current.validateAll();

    if (checkBtn.current.context._errors.length === 0) {
      AuthService.register(username, email, password, roles).then(
        (response) => {
          setMessage(response.data.message);
          setSuccessful(true);
          navigate('/users');
        },
        (error) => {
          const resMessage =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();

          setMessage(resMessage);
          setSuccessful(false);
        }
      );
    }
  };

  return (
    <div className='col-md-12'>
      <div className='login-card'>
        <div>
          <h1 className='login-title'>
            Реєстрація
          </h1>
        </div>
        <Form onSubmit={handleRegister} ref={form}>
          {!successful && (
            <div>
              <div className='form-group'>
                <label className='login-label' htmlFor='username'>
                  Логін <span>*</span>
                </label>
                <Input
                  type='text'
                  className='login-input form-control'
                  name='username'
                  value={username}
                  onChange={onChangeUsername}
                  validations={[required, vusername]}
                />
              </div>

              <div className='form-group'>
                <label className='login-label' htmlFor='email'>
                  Електронна пошта <span>*</span>
                </label>
                <Input
                  type='text'
                  className='login-input form-control'
                  name='email'
                  value={email}
                  onChange={onChangeEmail}
                  validations={[required, validEmail]}
                />
              </div>

              <div className='form-group'>
                <label className='login-label' htmlFor='password'>
                  Пароль <span>*</span>
                </label>
                <Input
                  type='password'
                  className='login-input form-control'
                  name='password'
                  value={password}
                  onChange={onChangePassword}
                  validations={[required, vpassword]}
                />
              </div>

              <div className='form-group'>
                <label className='login-label'>
                  Роль <span>*</span>
                </label>
                <div className='signup-roles' onChange={onChangeRole}>
                  <label>
                    <input type='radio' name="role" value='user' defaultChecked />
                    <span>Амбасадор</span>
                  </label>
                  <label>
                    <input type='radio' name="role" value='moderator' />
                    <span>Експерт</span>
                  </label>
                  <label>
                    <input type='radio' name="role" value='admin' />
                    <span>Адміністратор</span>
                  </label>
                </div>
              </div>

              {hasAdminRole
                ? <div className='form-group'>
                  <button className='btn btn-login'>
                    Зареєcтрувати
                  </button>
                </div>
                : <div className='mt-5 center text-center'>
                  У вас немає доступу до створення нових користувачів
                </div>}

            </div>
          )}

          {message && (
            <div className='form-group'>
              <div
                className={
                  successful ? 'alert alert-success' : 'alert alert-danger'
                }
                role='alert'
              >
                {message}
              </div>
            </div>
          )}
          <CheckButton style={{ display: 'none' }} ref={checkBtn} />
        </Form>
      </div>
    </div>
  );
};