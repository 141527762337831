import React from 'react';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';

//states
import { selectCurrentUser, selectAdminRole } from '../../features/authSlice';

//styles
import "./style.css";

//images
import logo from '../../assets/img/main-logo.png';

export default function Nav({ logOut }) {
    const currentUser = useSelector(selectCurrentUser);
    const hasAdminRole = useSelector(selectAdminRole);

    return (
        <nav className="navbar navbar-expand">
            <div className="container-fluid">
                <div className="navbar-brand">
                    <img className='navbar-logo' src={logo} alt='Logo' />
                </div>
                <div className="navbar-nav mr-auto">
                    {currentUser && !hasAdminRole && (
                        <>
                            <li className="nav-item">
                                <NavLink to={"/"} className="nav-link">
                                    Головна
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/category"} className="nav-link">
                                    Історії
                                </NavLink>
                            </li>
                        </>
                    )}

                    {currentUser && hasAdminRole && (
                        <>
                            <li className="nav-item">
                                <NavLink to={"/categories"} className="nav-link">
                                    Категорії
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/unverified-stories"} className="nav-link">
                                    Не перевірені
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/unpassed-stories"} className="nav-link">
                                    Відхилені
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/stories"} className="nav-link">
                                    Всі заявки
                                </NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink to={"/users"} className="nav-link">
                                    Користувачі
                                </NavLink>
                            </li>
                        </>
                    )}
                </div>

                {currentUser ? (
                    <>
                        <div className='navbar-nav ml-auto'>
                            <li className="nav-item">
                                <a className="btn nav-btn nav-btn-logout" href="/login" onClick={logOut}>
                                    Вихід
                                </a>
                            </li>
                        </div>
                    </>
                ) : (
                    <div className="navbar-nav ml-auto">
                        <li className="nav-item">
                            <NavLink className='btn nav-btn nav-btn-login' to={"/login"}>
                                Вхід
                            </NavLink>
                        </li>
                    </div>
                )}
            </div>
        </nav>
    )
}
