//styles
import './style.css';

export default function AssessmentCriteriaInput({ id, title, description, value, handler }) {
    return (
        <>
            <h4 className='assessment-criteria-title'>
                {title}
            </h4>
            <div className='assessment-criteria'>
                <div className='assessment-criteria-description'>
                    {description}
                </div>
                <div
                    className='assessment-criteria-radio-control'
                    value={value}
                    onChange={(e) => handler(e, id)}>
                    <label>
                        <input defaultChecked type='radio' name={'criteria-value' + id} value="1" />
                        <span>1</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="2" />
                        <span>2</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="3" />
                        <span>3</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="4" />
                        <span>4</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="5" />
                        <span>5</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="6" />
                        <span>6</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="7" />
                        <span>7</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="8" />
                        <span>8</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="9" />
                        <span>9</span>
                    </label>
                    <label>
                        <input type='radio' name={'criteria-value' + id} value="10" />
                        <span>10</span>
                    </label>
                </div>
            </div>
        </>
    )
}
