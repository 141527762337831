import { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';

//components
import Loader from '../../components/Loader/Loader';

//constans
import SERVER_URL from '../../constans/server-url';
import STORY_CATEGORIES from '../../constans/story-categories';

//styles
import './style.css';

//icons
import checkIcon from '../../assets/icons/check-green.svg';
import closeIcon from '../../assets/icons/close-red.svg';
import indeterminateIcon from '../../assets/icons/indeterminate-grey.svg';

export default function UnverifiedStoriesPage() {
    const [isLoading, setIsLoading] = useState(true);

    const [verifiedStoriesIds, setVerifiedStoriesIds] = useState([]);
    const [unverifiedStories, setUnverifiedStories] = useState([]);
    const [unverifiedStoriesIds, setUnverifiedStoriesIds] = useState([]);
    const [stories, setStories] = useState([]);

    useEffect(() => {
        axios.get(SERVER_URL + '/api/attribute/read-not-unverified-attributes')
            .then(response => {
                let array = response.data?.map((story) => {
                    return story.form_id;
                })
                if (array.length > 0) {
                    setVerifiedStoriesIds(array);
                } else {
                    setVerifiedStoriesIds([0]);
                }
            })
    }, []);

    useEffect(() => {
        verifiedStoriesIds.length > 0 && axios.post(SERVER_URL + '/api/stories/read-stories-by-forms-id-invert', { formsId: verifiedStoriesIds })
            .then((response) => {
                setUnverifiedStories(response.data);
                let array = response.data?.map((story) => {
                    return story.id;
                })
                setUnverifiedStoriesIds(array);
            })
    }, [verifiedStoriesIds]);

    useEffect(() => {
        unverifiedStoriesIds.length > 0 && axios.post(SERVER_URL + '/api/attribute/read-attributes-by-ids', { ids: unverifiedStoriesIds })
            .then((response) => {
                let data = response.data;
                let obj = {};
                data.forEach(user => {
                    obj[user.form_id] = {
                        story_validation: user.story_validation,
                        dream_validation: user.dream_validation,
                        category: user.category
                    }
                })

                let result = unverifiedStories.map(story => {
                    story.attributes = obj[story.id];
                    return story;
                })
                setIsLoading(false);
                setStories(result);
            });
    }, [unverifiedStoriesIds, unverifiedStories]);

    return (
        <>
            <main className='unverified-stories-page'>
                <div className='unverified-stories-page-title'>
                    <h1>Неперевірені заявки</h1>
                    <div>{stories.length}</div>
                </div>
                <table className='table'>
                    <thead>
                        <tr>
                            <th>№</th>
                            <th>Тип</th>
                            <th>Історія</th>
                            <th>Ідея</th>
                            <th>Категорія</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        {stories.map(story => {
                            return (
                                <tr key={story.id}>
                                    <td>{story.id}</td>
                                    <td>
                                        {(story.form === 3520)
                                            ? 'Власна історія вчителя'
                                            : 'Історія про вчителя'
                                        }
                                    </td>
                                    <td>
                                        {(story.attributes === undefined
                                            || story.attributes?.story_validation === null
                                            || story.attributes?.story_validation === 0)
                                            ? <img src={indeterminateIcon} alt='icon' />
                                            : (story.attributes?.story_validation === 1)
                                                ? <img src={checkIcon} alt='icon' />
                                                : <img src={closeIcon} alt='icon' />
                                        }
                                    </td>
                                    <td>
                                        {(story.attributes === undefined
                                            || story.attributes?.dream_validation === null
                                            || story.attributes?.dream_validation === 0)
                                            ? <img src={indeterminateIcon} alt='icon' />
                                            : (story.attributes?.dream_validation === 1)
                                                ? <img src={checkIcon} alt='icon' />
                                                : <img src={closeIcon} alt='icon' />
                                        }
                                    </td>
                                    <td>{(story.attributes === undefined
                                        || story.attributes.category === null)
                                        ? <img src={indeterminateIcon} alt='icon' />
                                        : STORY_CATEGORIES[story.attributes.category]
                                    }</td>
                                    <td>
                                        {(story.form === 3520)
                                            ? <Link className='btn' to={'/teacher-story/' + story.id}>Читати</Link>
                                            : <Link className='btn' to={'/about-teacher-story/' + story.id}>Читати</Link>
                                        }
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </main>
            {isLoading && <Loader />}
        </>
    )
}
