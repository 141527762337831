const DREAM_CRITERIA = [
    {
        title: 'Сприяння доступності освіти',
        description:
            'Оцінюється, наскільки проєкт сприятиме забезпеченню доступності якісної освіти Методологія оцінки Анкет для учнів деокупованих та прифронотових територій. Освітня ідея може включати заснування нових шкіл, відновлення або модернізацію існуючих, організацію доступу до додаткових освітніх ресурсів, матеріально-технічну допомогу тощо.',
    },
    {
        title: 'Розвиток програм і методик',
        description:
            'Оцінюється, наскільки проєкт сприятиме розвитку сучасних програм та методик навчання, які відповідають потребам учнів та забезпечують їх академічний, культурний та соціальний розвиток. Це може включати надання підтримки для впровадження інноваційних підходів до навчання та оцінювання, організацію тренінгів та підтримку для педагогів.',
    },
    {
        title: 'Створення інклюзивного середовища',
        description:
            'Оцінюється, наскільки проєкт сприятиме створенню інклюзивного середовища, де кожен учень та вчитель має рівні можливості для отримання якісної освітніх послуг. Це може включати впровадження заходів для підтримки дітей з особливими потребами, забезпечення доступу до ресурсів для всіх учнів та створення сприятливого клімату в навчальних закладах.',
    },
    {
        title: 'Сталість та ефективність проєкту',
        description:
            'Оцінюється, наскільки проєкт передбачає сталість та ефективність у відбудові освіти на деокупованих або прифронтових територіях України. Це включає оцінку фінансової стійкості проєкту, його впливу на довгостроковий розвиток освіти, планування ресурсів та здатності до масштабування.',
    },
    {
        title: 'Залучення громади та партнерство',
        description:
            'Оцінюється, наскільки проєкт передбачає залучення громади та партнерства з різними зацікавленими сторонами, такими як місцеві органи влади, батьківські комітети, місцеві підприємства та неприбуткові організації. Це може сприяти взаємодії, обміну ресурсами та підтримці з метою досягнення успіху проєкту.',
    },
];

export default DREAM_CRITERIA;
