import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';

//components
import AssessmentCriteriaInput from '../../components/AssessmentCriteria/AssessmentCriteriaInput';
import FeedbackInput from '../../components/FeedbackInput/FeedbackInput';
import RatingScale from '../RatingScale/RatingScale';

//states
import { selectCurrentUser, selectAdminRole } from '../../features/authSlice';

//styles
import './style.css';

//icons
import arrow from '../../assets/icons/arrow-down.svg';

export default function Evaluation({ title, criteria, criteriaHandler, criteriaArray, showFullForm, showFullFormHandler, feedback, feedbackHandler, feedbackLength, feedbackHeight, modalHandler, type }) {
    const hasAdminRole = useSelector(selectAdminRole);
    const currentUser = useSelector(selectCurrentUser);

    return (
        <>{!hasAdminRole &&
            <div className='evaluation'>
                <h3 onClick={() => showFullFormHandler(prevState => !prevState)}>
                    {title}
                    <img className={showFullForm ? 'evaluation-arrow-rotate' : 'evaluation-arrow'} src={arrow} alt='arrow' />
                </h3>
                <div hidden={!showFullForm}>
                    <RatingScale type={type} />
                    <form>
                        {criteriaArray.map((story, index) => {
                            return (
                                <AssessmentCriteriaInput
                                    key={index}
                                    id={index}
                                    title={story.title}
                                    description={story.description}
                                    value={criteria[index]}
                                    handler={criteriaHandler}
                                />
                            )
                        })}
                        <FeedbackInput
                            feedback={feedback}
                            handler={feedbackHandler}
                            length={feedbackLength}
                            height={feedbackHeight}
                        />
                        <div className='evaluation-footer'>
                            <button className='btn btn-primary' onClick={(e) => modalHandler(e)} disabled={!currentUser || !feedback}>
                                Надіслати
                            </button>
                        </div>
                    </form>
                </div>
            </div>}
        </>
    )
}
