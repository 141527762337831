//utilities
import decodeHTMLEntities from "../../features/decodeHTMLEntities"

export default function StoryInformation({ firstName, lastName, city }) {
    return (
        <>
            <h1 className='story-title'>
                {firstName} {lastName}
            </h1>
            <h2 className='story-city'>
                {city}
            </h2>
        </>
    )
}
